<template>
	<div class="h-100 d-flex flex-column position-relative">
		<loading-spinner v-if="isLoading"></loading-spinner>

		<div class="d-flex justify-content-between mt-4">
			<router-link to="/">
				<b-button size="lg" variant="primary" class="center-items">
					<b-icon icon="chevron-left" variant="light"></b-icon>
				</b-button>
			</router-link>
			<div>
				<b-button
					title="Invert selection"
					size="lg"
					class="mr-4"
					variant="primary"
					@click="invertSelectedSegment"
				>
					{{ invertCropTitle }}
				</b-button>

				<b-button class="mr-4" size="lg" variant="primary" @click="crop"
					>Crop</b-button
				>
				<b-button class="mr-4" size="lg" variant="primary" @click="save"
					>Save</b-button
				>
			</div>
		</div>

		<div class="position-relative flex-grow-1 w-100 center-items flex-column">
			<loading-spinner v-if="isPeaksLoading"></loading-spinner>

			<div
				class="controlsContainer position-relative mb-4"
				ref="controlsContainer"
			>
				<div
					class="peaksContainer mb-4 position-absolute"
					ref="zoomContainer"
					@wheel="scrollZoom"
				></div>
			</div>

			<!-- <div class="peaksContainer" ref="overviewContainer"></div> -->
		</div>

		<crop-history
			@historyEntrySelect="handleHistoryEntrySelect"
			:initialHistory="history"
			:scrollTrigger="cropHistoryScrollTrigger"
		></crop-history>

		<div class="my-4">
			<audio ref="audioElement">
				<source
					:src="history.current.audio.url"
					:type="history.current.audio.type"
				/>
				Your browser does not support the audio element.
			</audio>

			<div class="cropAudioControls">
				<controls
					:playing="playing"
					:cropFrom="cropFrom"
					:cropTo="cropTo"
					@zoom-in="zoomIn"
					@zoom-out="zoomOut"
					@zoom-full="setZoom(history.current.audio.duration)"
					@zoomSeconds="setZoom"
					@play="play"
					@pause="pauseSegment"
					@crop-from-updated="controlsCropFromUpdatedHandler"
					@crop-to-updated="controlsCropToUpdatedHandler"
				></controls>
			</div>
		</div>
	</div>
</template>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import Controls from "./Controls.vue";
import CropHistory from "./history/CropHistory.vue";
import { CropHistoryEntry, MemoCropHistory } from "@/lib/MemoCropHistory";
import { initPeaks } from "@/lib/initPeaks";
import Memo from "@/lib/Memo";
import LoadingSpinner from "../LoadingSpinner.vue";
import { ffmpegExtractSlice } from "@/lib/ffmpeg/ffmpegExtractSlice";
import { ffmpegResponseDoneToBlobs } from "@/lib/ffmpeg/utils";
import { MemoAudio } from "@/lib/MemoAudio";
import { blobToFile } from "@/lib/utils";
import { updateAudio } from "@/api/memo";
import { defaultLogger } from "@/lib/debug";
import { DnaVisualization, getNewAudioVisualizer } from "@/lib/AudioVisualizer";

const log = defaultLogger.extend("PeaksContainer");

/**
 * @typedef {Object} Segment
 * @property {string} id
 * @property {number} startTime
 * @property {number} endTime
 * @property {boolean} editable
 * @property {function({ startTime?: number, endTime?: number, labelText?: string, color?: string, editable?: boolean }): void} update
 */

/**
 * @param {number} startTime
 * @param {number} endTime
 * @param {object} props
 * @returns {Segment}
 */
function createSegment(startTime, endTime, props = {}) {
	return Object.assign(
		{},
		{
			id: uuidv4(),
			startTime,
			endTime,
			editable: true,
		},
		props
	);
}

/**
 * @param {Segment[]} segments
 * @returns {Segment[]}
 */
function sortSegments(segments) {
	return segments.sort((a, b) => a.startTime - b.startTime);
}

export default {
	name: "PeaksContainers",
	components: {
		Controls,
		CropHistory,
		LoadingSpinner,
	},

	data() {
		return {
			/** @type {Peaks} */
			peaks: null,
			currentTime: null,
			playing: false,
			isLoading: true,
			isPeaksLoading: false,
			playingSegmentId: 0,

			/** @type {MemoCropHistory|null} */
			history: null,
			/** @type {number} */
			cropHistoryScrollTrigger: 0,

			/** @type {Segment} */
			selectedSegment: null,
		};
	},

	computed: {
		...mapGetters("memo", ["currentMemo"]),

		cropFrom: {
			get() {
				if (!this.peaks) return 0;

				/** @type {Segment[]} */
				let segments = [...this.peaks.segments.getSegments()];

				if (segments.length > 1) {
					return sortSegments(segments)[0].endTime;
				} else if (segments.length === 1) {
					return segments[0].startTime;
				} else {
					log.extend("cropFrom:get")("Error: missing segments!");
					return 0;
				}
			},

			/** @param {number} value */
			set(value) {
				if (!this.peaks) return 0;

				/** @type {Segment[]} */
				let segments = [...this.peaks.segments.getSegments()];

				if (segments.length > 1) {
					sortSegments(segments)[0].update({ endTime: value });
				} else if (segments.length === 1) {
					segments[0].update({ startTime: value });
				} else {
					log.extend("cropFrom:set")("Error: missing segments!");
				}
			},
		},

		cropTo: {
			get() {
				if (!this.peaks) return 0;

				/** @type {Segment[]} */
				let segments = [...this.peaks.segments.getSegments()];

				if (segments.length === 1) {
					return segments[0].endTime;
				} else {
					segments = sortSegments(segments);
					return segments[1].startTime;
				}
			},

			/** @param {number} value */
			set(value) {
				if (!this.peaks) return 0;

				/** @type {Segment[]} */
				let segments = [...this.peaks.segments.getSegments()];

				if (segments.length === 1) {
					segments[0].update({ endTime: value });
				} else {
					segments = sortSegments(segments);
					segments[1].update({ startTime: value });
				}
			},
		},

		invertCropTitle() {
			if (!this.peaks) return 0;

			/** @type {Segment[]} */
			let segments = this.peaks.segments.getSegments();

			if (segments.length === 1) {
				return "Crop out";
			} else {
				return "Crop in";
			}
		},
	},

	methods: {
		...mapMutations("memo", ["setCurrentMemo"]),

		/**
		 * @param {number} value
		 */
		controlsCropFromUpdatedHandler(value) {
			if (value < 0) {
				value = 0;
			}
			this.cropFrom = value;
		},

		/**
		 * @param {number} value
		 */
		controlsCropToUpdatedHandler(value) {
			let duration = this.history.current.audio.duration;
			if (value > duration) {
				value = duration;
			}
			this.cropTo = value;
		},

		scrollZoom(e) {
			if (e.deltaY < 0) {
				this.zoomOut();
			} else {
				this.zoomIn();
			}
		},

		zoomIn() {
			this.setZoom(this.getZoom() - 1);
		},

		zoomOut() {
			this.setZoom(this.getZoom() + 1);
		},

		playSelectedSegment() {
			this.peaks.player.playSegment(this.selectedSegment);
		},

		playSegment(next) {
			let segments = [...this.peaks.segments.getSegments()];
			if (segments.length === 0) return;
			sortSegments(segments);
			let i = 0;
			let found = false;

			if (!this.playingSegmentId) {
				this.playingSegmentId = segments[0].id;
			} else {
				for (; i < segments.length; i++) {
					if (segments[i].id === this.playingSegmentId) {
						found = true;
						break;
					}
				}
			}
			if (!found) i = 0;
			let segment = segments[i];
			if (next) {
				segment = segments[(i + 1) % segments.length];
				this.playingSegmentId = segment.id;
			}

			this.peaks.player.playSegment(segment);
		},

		play() {
			this.playing = true;
			this.playSegment();
			if (this.currentTime !== null) {
				const segment = this.peaks.segments.getSegment(this.playingSegmentId);
				if (this.currentTime > segment.endTime) {
					this.playNextSegment();
					return;
				} else if (this.currentTime < segment.startTime) {
					this.currentTime = segment.startTime;
				}
				this.peaks.player.seek(this.currentTime);
			}
		},

		playNextSegment() {
			if (this.playing) {
				this.playSegment(true);
			}
		},

		pauseSegment() {
			this.playing = false;
			this.currentTime = this.peaks.player.getCurrentTime();
			this.peaks.player.pause();
		},

		startSecond() {
			const zoomview = this.getZoomview();
			return zoomview
				? (zoomview._frameOffset * zoomview._data.scale) /
						zoomview._data.sample_rate
				: 0;
		},

		setZoom(seconds) {
			if (seconds <= 0 || seconds > this.history.current.audio.duration) return;
			this.getZoomview().setZoom({ seconds });
		},

		getZoom() {
			const zoomview = this.getZoomview();
			return zoomview ? zoomview._zoomLevelSeconds : 1;
		},

		getZoomview() {
			return this.peaks ? this.peaks.views._zoomview : null;
		},

		posToTime(pos) {
			return pos * this.getZoom() + this.startSecond();
		},

		timeToPos(time) {
			return (time - this.startSecond()) / this.getZoom();
		},

		getOverlappingSegment(time, segments) {
			if (!segments) segments = this.peaks.segments.getSegments();
			return segments.find(
				(segment) => segment.startTime < time && segment.endTime > time
			);
		},

		invertSelectedSegment() {
			let _log = log.extend("invertSelectedSegment");
			_log(this);

			/** @type {Segment[]} */
			let segments = [...this.peaks.segments.getSegments()];
			_log(segments);

			if (segments.length == 1) {
				const segment = segments[0];
				_log("segment: %O", segment);

				const segmentA = createSegment(0, segment.startTime, {
					id: "first",
				});
				_log("segmentA: %O", segment);

				const segmentB = createSegment(
					segment.endTime,
					this.history.current.audio.duration,
					{ id: "last" }
				);
				_log("segmentB: %O", segment);

				this.peaks.segments.removeById("single");
				this.peaks.segments.add([segmentA, segmentB]);
				_log("added");
			} else {
				segments = sortSegments(segments);

				const firstSegment = segments[0];
				const lastSegment = segments[1];
				const segment = createSegment(
					firstSegment.endTime,
					lastSegment.startTime,
					{ id: "single" }
				);

				this.peaks.segments.removeById(firstSegment.id);
				this.peaks.segments.removeById(lastSegment.id);
				this.peaks.segments.add([segment]);
				this.selectedSegment = segment;
			}
		},

		checkOverlappings(currentSegment, inMarker) {
			const time = inMarker ? currentSegment.startTime : currentSegment.endTime;
			const overlappingSegment = this.getOverlappingSegment(time);
			if (!overlappingSegment) return;
			if (inMarker) {
				// overlappingSegment.update({ endTime: time - 0.02 * this.getZoom() });
				currentSegment.update({
					startTime: overlappingSegment.endTime + 0.01 * this.getZoom(),
				});
			} else {
				// overlappingSegment.update({ startTime: time + 0.02 * this.getZoom() });
				currentSegment.update({
					endTime: overlappingSegment.startTime - 0.01 * this.getZoom(),
				});
			}
		},

		async crop() {
			this.isPeaksLoading = true;
			this.playing = false;
			this.peaks.player.pause();

			/** @type {Segment[]} */
			let segments = [...this.peaks.segments.getSegments()];
			if (segments.length > 1) {
				segments = sortSegments(segments);
			}

			// Crop
			const newMemoAudio = await ffmpegExtractSlice(
				blobToFile(this.history.current.audio.blob, this.currentMemo.title),
				segments
			)
				.then(ffmpegResponseDoneToBlobs)
				.then((blobs) => new MemoAudio(blobs[0]));

			// if (this.history.isLastSelected()) {
			this.history.append(newMemoAudio);
			// } else {
			//   this.history.replace(newMemoAudio);
			// }

			newMemoAudio.decode().then((audioBuffer) => {
				this.initPeaks({ audioBuffer });
				this.isPeaksLoading = false;
				++this.cropHistoryScrollTrigger;
			});
		},

		/**
		 * @param {CropHistoryEntry} historyEntry
		 */
		handleHistoryEntrySelect(historyEntry) {
			historyEntry.audio.decode().then((audioBuffer) => {
				this.isPeaksLoading = true;
				this.initPeaks({ audioBuffer });
			});
		},

		async save() {
			this.playing = false;
			this.peaks.player.pause();
			this.currentMemo.setAudio(this.history.current.audio);

			this.$swal.fire({
				icon: "warning",
				title: "Saving...",
			});
			if (this.currentMemo.id) {
				updateAudio(this.currentMemo)
					.then(() => {
						this.$swal.fire({
							icon: "success",
							title: "Saved",
							timer: 2000,
							timerProgressBar: true,
						});
						this.$router.push("/");
					})
					.catch((err) => {
						console.error(err);
						this.$swal.fire({
							icon: "error",
							title: "Failed to save",
							timer: 4000,
							timerProgressBar: true,
						});
					});
			}

			// this.audioVisualizer = await getNewAudioVisualizer({
			//   src: URL.createObjectURL(this.currentMemo.audio.blob),
			// });
			// const visualization = new DnaVisualization(this.audioVisualizer, {
			//   scale: 0.1,
			// });
			// this.audioVisualizer.addVisualization(visualization);

			// this.audioVisualizer.on("stopped", () => {
			//   visualization.render().then(() => {
			//     visualization.getCanvas().toBlob((blob) => {
			//       console.log(this.currentMemo, this.audioVisualizer, visualization.getCanvas())
			//       // this.currentMemo.imageBlob = blob;
			//       // updateAudio(this.currentMemo)
			//       //   .then(() => {
			//       //     this.$swal.fire({
			//       //       icon: "success",
			//       //       title: "Saved",
			//       //       timer: 2000,
			//       //       timerProgressBar: true,
			//       //     });
			//       //     this.$router.push("/");
			//       //   })
			//       //   .catch((err) => {
			//       //     console.error(err);
			//       //     this.$swal.fire({
			//       //       icon: "error",
			//       //       title: "Failed to save",
			//       //       timer: 4000,
			//       //       timerProgressBar: true,
			//       //     });
			//       //   });
			//     });
			//   });
			// });
		},

		/**
		 * @param {{audioBuffer?: AudioBuffer, memo?: Memo}} peaksProps
		 */
		async initPeaks(peaksProps = {}) {
			if (!(peaksProps.audioBuffer || peaksProps.memo)) {
				throw "audioBuffer or memo is required to be present";
			}

			if (this.peaks) {
				this.peaks.destroy();
				this.peaks = null;
			}

			this.peaks = await initPeaks(
				{
					zoomview: this.$refs.zoomContainer,
					// overview: this.$refs.overviewContainer,
				},
				this.$refs.audioElement,
				peaksProps
			);
			this.setZoom(this.history.current.audio.duration);

			this.isPeaksLoading = false;
			this.isLoading = false;

			const zoom = this.getZoom();
			const newSegment = createSegment(zoom * 0.001, zoom * 0.999, {
				id: "single",
			});
			this.peaks.segments.add([newSegment]);
			this.selectedSegment = newSegment;

			this.cropFrom = 0;
			this.cropTo = this.history.current.audio.duration;

			this.peaks.on("player.pause", this.playNextSegment.bind(this));
			this.peaks.on("segments.dragged", this.segmentsDraggedHander.bind(this));
		},

		/**
		 * @param {Segment} segment
		 * @param {boolean} handle
		 */
		segmentsDraggedHander(segment, handle) {
			let _log = log.extend("segmentsDraggedHander");
			_log("this: %O; segment: %O; handle: %s", this, segment, handle);

			this.checkOverlappings(segment, handle);

			this.selectedSegment = segment;

			switch (segment.id) {
				case "first":
					if (handle) {
						segment.update({ startTime: 0 });
					}
					break;

				case "last":
					if (!handle) {
						segment.update({ endTime: this.history.current.audio.duration });
					}
					break;
			}
		},
	},

	created() {
		this.isLoading = true;
		if (!this.currentMemo) {
			this.$router.push("/");
		}

		this.history = new MemoCropHistory(this.currentMemo);
	},

	async mounted() {
		this.$nextTick(async () => {
			if (!this.currentMemo) {
				this.$router.push("/");
			}

			await this.initPeaks({ memo: this.currentMemo });
		});
	},
};
</script>

<style scoped lang="scss">
.controlsContainer {
	width: 100%;
	max-width: 1060px;
	min-height: 100px;
}

.peaksContainer {
	width: 100%;
	max-width: 1060px;
	min-height: 100px;
}

.spinner {
	width: 5em;
}
.spinner-container {
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: white;
}
</style>
