<template>
	<div class="h-100 d-flex flex-column">
		<div>
			<navbar />
		</div>

		<router-view />
	</div>
</template>
<script>
// @ts-check
import Navbar from "@/components/Navbar.vue";
import { login } from "@/api/user";
import { mapGetters, mapMutations } from "vuex";
import { listMemos } from "./api/memo";
import * as api from "@/api";
import Memo from "./lib/Memo";

export default {
	name: "App",
	components: {
		Navbar,
	},

	computed: {
		...mapGetters("user", ["currentUser"]),
	},

	methods: {
		...mapMutations("user", ["setCurrentUser"]),
		...mapMutations("memo", ["setMemos"]),
		...mapMutations("playlist", ["setPlaylists"]),
	},

	async mounted() {
		if (!this.currentUser) {
			const user = await login();
			this.setCurrentUser(user);

			const memos = await listMemos();
			this.setMemos(memos.map(Memo.fromObject));

			const playlists = await api.playlist.list();
			this.setPlaylists(playlists);
		}
	},
};
</script>
