<template>
	<b-modal
		size="lg"
		centered
		hide-footer
		no-stacking
		v-model="showModal"
		v-if="currentMemo"
		body-class="p-0"
		v-on="$listeners"
		@cancel="handleModalClose"
		@close="handleModalClose"
	>
		<template #modal-title v-if="!editMode">
			<ModalHeader />
		</template>

		<RegularView v-if="!memoModalRichView && !editMode" :memo="currentMemo" />
		<AdvancedView v-else-if="!editMode" :memo="currentMemo" />
		<EditView v-else @discard="handleDiscardEvent" />
	</b-modal>
</template>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";
import ModalHeader from "./ModalHeader.vue";
import RegularView from "./View/RegularView.vue";
import AdvancedView from "./View/AdvancedView.vue";
import EditView from "./View/EditView.vue";

export default {
	name: "MemoDetails",

	components: { ModalHeader, RegularView, AdvancedView, EditView },

	computed: {
		...mapGetters("memo", ["editMode", "currentMemo"]),
		...mapGetters("view", ["memoDetailsViewOpened", "memoModalRichView"]),

		showModal: {
			get() {
				return this.memoDetailsViewOpened;
			},

			set(val) {
				this.setMemoDetailsViewOpened(val);
			},
		},
	},

	methods: {
		...mapMutations("memo", ["setCurrentMemo", "setEditMode"]),
		...mapMutations("view", ["setMemoDetailsViewOpened"]),

		handleDiscardEvent() {
			this.setEditMode(false);
			this.$emit("discard", ...arguments);
		},

		handleModalClose() {
			if (!this.currentMemo.id) {
				this.$emit("discard");
			}
		},
	},
};
</script>
