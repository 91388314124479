<template>
	<audio ref="audioElement" v-on="$listeners">
		<source :src="src" :type="type" />
		Your browser does not support the audio element.
	</audio>
</template>

<script>
// @ts-check

export default {
	props: {
		src: {
			type: String,
			required: true,
		},

		type: {
			type: String,
			required: true,
		},
	},

	methods: {
		play() {
			if (this.$refs.audioElement instanceof HTMLAudioElement) {
				this.$refs.audioElement.play();
			}
		},

		pause() {
			if (this.$refs.audioElement instanceof HTMLAudioElement) {
				this.$refs.audioElement.pause();
			}
		},

		getCurrentTime() {
			if (this.$refs.audioElement instanceof HTMLAudioElement) {
				return this.$refs.audioElement.currentTime;
			}
			return 0;
		},

		setCurrentTime(time) {
			if (this.$refs.audioElement instanceof HTMLAudioElement) {
				this.$refs.audioElement.currentTime = time;
			}
		},
	},
};
</script>
