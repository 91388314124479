<template>
	<div class="w-100" ref="container">
		<canvas ref="canvas" />
	</div>
</template>

<style scoped>
canvas {
	float: left;
}
</style>

<script>
// @ts-check
import {
	WebGLRenderer,
	Color,
	Scene,
	OrthographicCamera,
	PlaneGeometry,
	MeshBasicMaterial,
	Object3D,
	InstancedMesh,
	DynamicDrawUsage,
} from "three";

const height = 200;

const dataSize = 1000000;

export default {
	name: "RecordVolumeBars",
	props: ["bus", "color"],

	data() {
		return {
			renderer: null,
			width: 0,
			scene: null,
			camera: null,
			material: undefined,
			dummy: new Object3D(),
			mesh: undefined,
			volumes: [new Float32Array(dataSize)],
			count: 0,
		};
	},

	methods: {
		updateBars() {
			for (let i = 0; i < this.count; ++i) {
				const volume = this.volumes[Math.round(this.count / dataSize)][i];

				this.dummy.scale.y = volume * 2;
				this.dummy.scale.x = 2 / this.count;

				this.dummy.position.set(
					this.dummy.scale.x / 2 - 1 + this.dummy.scale.x * i,
					volume - 1
				);

				this.dummy.updateMatrix();
				this.mesh.setMatrixAt(i, this.dummy.matrix);
			}
		},

		createBar(volume) {
			this.volumes[Math.round(this.count / dataSize)][this.count++] = volume;
			if (this.count > dataSize) {
				this.volumes.push(new Float32Array(dataSize));
			}
			this.updateBars();
		},

		render() {
			let width = (this.$refs.container || {}).clientWidth || 0;
			if (width && width != this.width) {
				this.renderer.setSize(width, height);
			}

			this.mesh.instanceMatrix.needsUpdate = true;
			this.renderer.render(this.scene, this.camera);
		},
	},

	mounted() {
		this.renderer = new WebGLRenderer({
			antialias: true,
			canvas: this.$refs.canvas,
		});
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setClearColor(new Color(1, 1, 1));
		this.renderer.clearColor();

		this.renderer.setAnimationLoop(() => {
			this.render();
		});

		this.bus.$on("volume", (volume) => this.createBar(volume));
	},

	created() {
		this.scene = new Scene();
		this.scene.matrixAutoUpdate = false;
		// this.scene.background = new Color("skyblue"); // this helps debug if canvas is even rendering
		this.scene.updateMatrix();

		const zoom = 1; // 1
		this.camera = new OrthographicCamera(-zoom, zoom, zoom, -zoom);
		this.camera.position.z = 1;

		this.material = new MeshBasicMaterial({ color: this.color || 0x399dfa });

		this.mesh = new InstancedMesh(new PlaneGeometry(), this.material, dataSize);
		this.mesh.instanceMatrix.setUsage(DynamicDrawUsage); // will be updated every frame
		this.scene.add(this.mesh);
	},
};
</script>
