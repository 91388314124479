// @ts-check

import WaveformData from "waveform-data";
import { getNewAudioContext, toAudioBuffer } from "@/lib/soundUtils";

const audioContext = getNewAudioContext();

export class Waveform {
  /**
   * @param {WaveformData} data
   */
  constructor(data) {
    this._waveform = data;
  }

  getWaveformData() {
    return this._waveform;
  }

  scaleY(amplitude, height) {
    const range = 256;
    const offset = 128;

    return height - ((amplitude + offset) * height) / range;
  }

  drawWaveform(to = 1) {
    if (to > 1) return;
    this.ctx.beginPath();

    const end = Math.floor(this._waveform.length * to);

    // Loop forwards, drawing the upper half of the waveform
    for (let x = 0; x < end; x++) {
      const val = this.channel.max_sample(x);
      this.ctx.lineTo(
        x * this.scaleX,
        this.scaleY(val, this.canvas.height) + 0.5
      );
    }

    // Loop backwards, drawing the lower half of the waveform
    for (let x = end - 1; x >= 0; x--) {
      const val = this.channel.min_sample(x);
      this.ctx.lineTo(
        x * this.scaleX,
        this.scaleY(val, this.canvas.height) + 0.5
      );
    }

    this.ctx.closePath();
    this.ctx.fill();
  }

  draw(percent, alpha) {
    if (!this.ctx) {
      throw "Canvas is not initialized";
    }

    this.ctx.clearRect(
      0,
      0,
      Math.floor(this.canvas.width * percent),
      this.canvas.height
    );

    this.ctx.save();
    this.ctx.globalAlpha = alpha;
    this.drawWaveform(percent);
    this.ctx.restore();
  }

  initCanvas(canvas) {
    this.canvas = canvas;
    this.initDraw = true;
    this.ctx = this.canvas.getContext("2d");

    const dPR = window.devicePixelRatio || 1;
    this.ctx.scale(dPR, dPR);

    this.scaleX = this.canvas.width / this._waveform.length;

    this.channel = this._waveform.channel(0);
  }
}

function createWaveformData(options) {
  return new Promise((resolve, reject) => {
    WaveformData.createFromAudio(options, (err, waveform) => {
      if (err) {
        reject(err);
      } else {
        resolve(waveform);
      }
    });
  });
}

/**
 * @param {ArrayBuffer} arrayBuffer
 * @returns {Promise<WaveformData>}
 */
createWaveformData.fromArrayBuffer = (arrayBuffer) => {
  return createWaveformData({
    audio_context: audioContext,
    array_buffer: arrayBuffer,
    scale: 128,
  });
};

/**
 * @param {Float32Array} float32Array
 * @returns {Promise<WaveformData>}
 */
createWaveformData.fromFloat32Array = (float32Array) => {
  return createWaveformData({
    audio_buffer: toAudioBuffer([float32Array]),
  });
};

/**
 * @param {AudioBuffer} audioBuffer
 * @returns {Promise<WaveformData>}
 */
createWaveformData.fromAudioBuffer = (audioBuffer) => {
  return createWaveformData({
    audio_buffer: audioBuffer,
  });
};

/**
 * @param {ArrayBuffer|Float32Array|AudioBuffer} data
 * @returns {Promise<WaveformData>}
 */
createWaveformData.from = (data) => {
  if (data instanceof ArrayBuffer) {
    return createWaveformData.fromArrayBuffer(data);
  } else if (data instanceof Float32Array) {
    return createWaveformData.fromFloat32Array(data);
  } else if (data instanceof AudioBuffer) {
    return createWaveformData.fromAudioBuffer(data);
  }

  throw new Error(`Unsupported type of data: ${data}`);
};

/**
 * @param {ArrayBuffer|Float32Array|AudioBuffer} data
 * @returns {Promise<Waveform>}
 */
export function createWaveform(data) {
  return createWaveformData
    .from(data)
    .then((waveformData) => new Waveform(waveformData));
}
