<template>
	<div
		class="mt-2 pb-3 mb-sm-3 d-flex justify-content-center flex-column flex-sm-row"
	>
		<div class="d-flex flex-grow-1 flex-column mr-sm-2">
			<MemoWaveformSwitcher
				ref="MemoWaveformSwitcher"
				:memo="memo"
				:opacity="0.2"
			/>

			<div class="px-3 d-flex flex-grow-1 flex-column">
				<div class="mt-4 font-30">{{ memo.title }}</div>

				<div class="mt-4 mb-3 text-muted">Description:</div>

				<div class="mb-2 description-body" v-html="memo.description"></div>

				<b-form-checkbox class="align-self-end" v-model="playDenoise">
					Denoise
				</b-form-checkbox>

				<MemoPlayer ref="MemoPlayer" :memo="memo" @ended="handleAudioEnded" />

				<b-button pill variant="primary" @click="togglePlay">{{
					isPlaying ? "Pause" : "Play"
				}}</b-button>
			</div>
		</div>

		<div v-if="memosFromSamePlaylist.length" class="memo-thumbnails">
			<hr class="w-100 d-block d-sm-none" />

			<div class="flex-row px-3 overflow-auto d-flex flex-sm-column ml-sm-2">
				<MemoThumbnail
					v-for="memo in memosFromSamePlaylist"
					:key="memo.id"
					:memo="memo"
					@click="handleMemoThumbnailClick"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.rounded-full {
	border-radius: 9999px;
}
.memo-thumbnails {
	min-width: 273px;
	overflow: hidden;
	border-radius: 0 0 15px 15px;
}

.description-body {
	white-space: pre-line;
}
</style>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";
import MemoWaveformSwitcher from "../../MemoWaveform/MemoWaveformSwitcher.vue";
import MemoThumbnail from "../../MemoThumbnail.vue";
import { patchIsSelectedDenoised } from "@/api/memo";
import Memo from "@/lib/Memo";
import MemoPlayer from "../../MemoPlayer.vue";

export default {
	components: {
		MemoWaveformSwitcher,
		MemoPlayer,
		MemoThumbnail,
	},

	props: {
		memo: {
			type: Memo,
			required: true,
		},
	},

	data() {
		return {
			/** @type {boolean} */
			isPlaying: false,

			/** @type {Array} */
			memosFromSamePlaylist: [],
		};
	},

	computed: {
		...mapGetters("memo", ["memos"]),

		playDenoise: {
			/** @returns {boolean} */
			get() {
				return this.memo.isSelectedDenoised;
			},

			/** @param {boolean} val */
			async set(val) {
				try {
					if (this.$refs.MemoPlayer) {
						this.$refs.MemoPlayer.pause();
						this.$refs.MemoPlayer.setCurrentTime(0);
					}

					this.isPlaying = false;

					if (val) {
						await this.memo.audio.denoise();
					}

					this.memo.isSelectedDenoised = val;
					patchIsSelectedDenoised(this.memo);
				} catch (e) {
					console.error(e);
				}
			},
		},
	},

	methods: {
		...mapMutations("memo", [
			"setEditMode",
			"deleteMemo",
			"setCurrentMemo",
			"editMemo",
			"updatememo",
		]),

		handleAudioEnded() {
			this.isPlaying = false;
			if (this.$refs.MemoWaveformSwitcher) {
				this.$refs.MemoWaveformSwitcher.restoreWaveform();
			}
		},

		handleAudioTimeUpdated() {
			if (!this.isPlaying) return;
			window.requestAnimationFrame(this.handleAudioTimeUpdated);
			this.fillProgress();
		},

		fillProgress() {
			if (this.$refs.MemoPlayer && this.$refs.MemoWaveformSwitcher) {
				const percent =
					this.$refs.MemoPlayer.getCurrentTime() / this.memo.audio.duration;

				this.$refs.MemoWaveformSwitcher.draw(percent, 0.8);
			}
		},

		onDelete() {
			this.deleteMemo(this.memo);
			this.setCurrentMemo(null);
		},

		togglePlay() {
			if (this.isPlaying) {
				this.$refs.MemoPlayer.pause();
			} else {
				this.$refs.MemoPlayer.play();
			}
			this.isPlaying = !this.isPlaying;
			this.handleAudioTimeUpdated();
		},

		findMemosFromSamePlaylist() {
			this.memosFromSamePlaylist = [...this.memos].filter(
				(m) =>
					m.id !== this.memo.id &&
					m.playlists.filter((p) => this.memo.playlists.indexOf(p) !== -1)
						.length
			);
		},

		handleMemoThumbnailClick() {
			if (this.isPlaying) {
				this.togglePlay();
			}
		},
	},

	watch: {
		memos() {
			this.findMemosFromSamePlaylist();
		},

		memo() {
			this.findMemosFromSamePlaylist();
		},
	},

	mounted() {
		this.findMemosFromSamePlaylist();
	},
};
</script>
