import { AudioVisualizer } from "./AudioVisualizer";
import { VisualizationLOGGER } from "./debug";
import { createCanvasInContainer, registerResizeObserver } from "./utils";

/**
 * @implements {VisualizationInterface}
 */
export class AbstractVisualization {
  /** @property {AudioVisualizer} */
  audioVisualizer;
  /** @property {Object} */
  options;
  /** @property {HTMLCanvasElement} */
  cnv;
  /** @property {CanvasRenderingContext2D} */
  ctx;
  /** @property {boolean} */
  _isStopped = false;

  /**
   * @param {AudioVisualizer} audioVisualizer
   * @param {import("./AudioVisualizer").VisualizationOptions} [options]
   * @returns
   */
  constructor(audioVisualizer, options = {}) {
    VisualizationLOGGER(
      "constructor:\naudioVisualizer: %o;\noptions: %o",
      audioVisualizer,
      options
    );

    this.audioVisualizer = audioVisualizer;
    this.options = options;

    if (options.canvas) {
      this.cnv = options.canvas;
    } else if (options.container) {
      this.cnv = createCanvasInContainer(options.container);
    } else {
      this.cnv = document.createElement("canvas");
      this.cnv.setAttribute("width", "200");
      this.cnv.setAttribute("height", "200");
    }

    if (typeof this.cnv.transferControlToOffscreen !== "function") {
      this.initContext();
    }

    if (this.ctx) {
      this.fillBackground();
    }

    registerResizeObserver(this.cnv, this.ctx);
  }

  /**
   * Draws the visualization.
   * @param {Object} props
   */
  update(props = {}) {
    throw new Error("not implemented");
  }

  /**
   * @returns {HTMLCanvasElement}
   */
  getCanvas() {
    return this.cnv;
  }

  initContext() {
    this.ctx = this.cnv.getContext("2d");
  }

  fillBackground() {
    this.ctx.fillStyle =
      this.options.background ||
      AbstractVisualization.DefaultOptions.background;
    this.ctx.fillRect(0, 0, this.cnv.width, this.cnv.height);
  }

  stop() {
    this._isStopped = true;
  }

  get isStopped() {
    return this._isStopped;
  }
}

AbstractVisualization.DefaultOptions = {
  background: "rgb(0, 0, 128)",

  lineWidth: 4,
  strokeStyle: "rgb(0,  128, 255)",

  rowsPerSec: 40,

  colortheme: [
    "#000080",
    "#00f",
    "#0080ff",
    "#0ff",
    "#80ff80",
    "#ff0",
    "#ff8000",
    "#f00",
    "#800000",
  ],
};
Object.freeze(AbstractVisualization.DefaultOptions);
