<template>
	<b-modal
		id="add-memo-to-playlist-modal"
		hide-footer
		header-class="pb-1"
		body-class="pt-0"
		@hide="handleModalClose"
		@close="handleModalClose"
	>
		<template #modal-header="{ close }">
			<h6>Add to playlist</h6>

			<button type="button" aria-label="Close" class="close" @click="close()">
				×
			</button>
		</template>

		<div class="d-flex flex-column">
			<h2 class="pb-3">{{ memo.title }}</h2>

			<div
				class="flex-row mb-3 double-btn d-flex w-100"
				v-for="(playlist, playlistId) in playlists"
				:key="playlistId"
				:data-is-in-playlist="isMemoInPlaylist(playlistId)"
			>
				<button
					class="flex-grow py-1 playlist-btn w-100"
					@click="playlistClickHandler(playlistId)"
				>
					<div class="checkbox">
						<b-icon icon="check" v-if="isMemoInPlaylist(playlistId)" />
					</div>

					<span>{{ playlist.name }}</span>
				</button>

				<button
					class="delete-btn"
					title="Delete playlist"
					@click="del(playlistId)"
				>
					<b-icon icon="trash" />
				</button>
			</div>

			<b-button v-b-modal.playlist-create-modal variant="primary">
				Create playlist
			</b-button>
		</div>
	</b-modal>
</template>

<style scoped>
.double-btn {
	--border-color: gray;

	position: relative;
	border: 1px solid var(--border-color);
	border-radius: 0.25rem;
}
.double-btn:hover {
	background: hsla(134, 61%, 41%, 0.175);
}
.double-btn:active {
	background: hsla(134, 61%, 41%, 0.25);
}
.double-btn[data-is-in-playlist] {
	--border-color: hsla(134, 61%, 21%, 0.5);
}
.double-btn[data-is-in-playlist] button {
	background: hsla(134, 61%, 41%, 0.1);
}

.double-btn button {
	border: none;
	background: transparent;
}

.double-btn .playlist-btn .checkbox {
	position: absolute;
	left: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
	width: 16px;
	height: 16px;
	border: 1px solid var(--border-color);
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
}

.double-btn .delete-btn {
	padding: 0 0.5rem;
}
.double-btn .delete-btn:hover {
	background: hsla(354, 70%, 54%, 0.175);
}
.double-btn .delete-btn:active {
	background: hsla(354, 70%, 54%, 0.25);
}
</style>

<script>
// @ts-check
import * as api from "@/api";
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "AddMemoModal",

	props: {
		memo: {
			type: Object,
			required: true,
		},
	},

	computed: {
		...mapGetters("playlist", ["playlists"]),
		...mapGetters("view", ["previousView"]),
	},

	methods: {
		...mapMutations("memo", [
			"addToPlaylist",
			"removeFromPlaylist",
			"setCurrentMemo",
		]),
		...mapMutations("playlist", ["deletePlaylist"]),
		...mapMutations("view", [
			"setMemoAddToPlaylistView",
			"setMemoDetailsViewOpened",
		]),

		async playlistClickHandler(playlistId) {
			try {
				if (this.isMemoInPlaylist(playlistId)) {
					await api.playlist.removeMemo(playlistId, this.memo.id);
					this.removeFromPlaylist({
						memoId: this.memo.id,
						playlistId: playlistId,
					});
				} else {
					await api.playlist.addMemo(playlistId, this.memo.id);
					this.addToPlaylist({ memoId: this.memo.id, playlistId: playlistId });
				}
			} catch (e) {
				console.error(e);
				this.$swal.fire({
					icon: "error",
					title: "Failed to update",
					timer: 4000,
					timerProgressBar: true,
				});
			}
		},

		isMemoInPlaylist(playlistId) {
			return this.memo.playlists.indexOf(playlistId) !== -1;
		},

		async del(playlistId) {
			try {
				await api.playlist.del(playlistId);
				this.deletePlaylist(playlistId);
				this.$swal.fire({
					icon: "success",
					title: "Deleted",
					timer: 2000,
					timerProgressBar: true,
				});
			} catch (e) {
				console.error(e);
				this.$swal.fire({
					icon: "error",
					title: "Failed to delete",
					timer: 4000,
					timerProgressBar: true,
				});
			}
		},

		handleModalClose() {
			if (this.previousView !== null && this.previousView === 2) {
				this.setMemoDetailsViewOpened(true);
			}
			this.setMemoAddToPlaylistView(false);
		},
	},

	beforeDestroy() {
		this.handleModalClose();
	},
};
</script>
