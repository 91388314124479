import * as EventEmitter from "eventemitter3";

// It would be even better to run timer in a webworker since
// tab could be "shadowed" causing all on-page js to pause
export class Timer extends EventEmitter {
  /** @type {boolean} */
  _isStopped = false;
  /** @type {number} */
  _time = 0;
  /** @type {number|undefined} */
  _lastUpdateTime = undefined;

  get time() {
    return this._time;
  }

  stop() {
    this._isStopped = true;
    this.emit("stop", this._time);
    this._time = 0;
    this._lastUpdateTime = undefined;
  }

  pause() {
    this._isStopped = true;
    this.emit("pause", this._time);
    this._lastUpdateTime = undefined;
  }

  start() {
    this._isStopped = false;
    window.requestAnimationFrame(this._update.bind(this));
  }

  resume() {
    this.start();
  }

  _update(timestamp) {
    if (this._isStopped) return;

    if (this._lastUpdateTime) {
      this._time += timestamp - this._lastUpdateTime;
    }

    this._lastUpdateTime = timestamp;

    this.emit("update", this._time);
    window.requestAnimationFrame(this._update.bind(this));
  }
}
