// @ts-check

import { createBlobFrom, extractExtensionFromFileName } from "../utils";

/**
 * @param {import("./ffmpeg").FfmpegResponseDone<ArrayLike>} data
 * @param {{outCodec?: string}} props
 * @returns {Blob[]}
 */
export function ffmpegResponseDoneToBlobs(data, props = {}) {
  return data.MEMFS.map((file) => {
    const fileExtension = extractExtensionFromFileName(file.name);

    return createBlobFrom([file.data], {
      type: "audio/" + fileExtension + getCodecsString(props.outCodec),
    });
  });
}

/**
 * @param {string} outCodec
 * @returns {string}
 */
function getCodecsString(outCodec) {
  if (outCodec) {
    return ";codecs=" + ffmpegCodecToBlobCodec(outCodec);
  }

  return "";
}

/**
 * @param {string} ffmpegCodec
 * @returns {string}
 */
function ffmpegCodecToBlobCodec(ffmpegCodec) {
  switch (ffmpegCodec) {
    case "libopus":
      return "opus"; // opus is an experimental codec in ffmpeg which is why libopus must be used

    default:
      return ffmpegCodec;
  }
}
