// @ts-check

import { extractExtensionFromFileName } from "../utils";

export class FfmpegFile {
  /**
   * @param {string} name
   * @param {Transferable} data
   */
  constructor(name, data) {
    this.name = name;
    this.extension = extractExtensionFromFileName(name);
    this.data = data;
  }

  /**
   * @param {string} [arg]
   * @returns {string[]}
   */
  getArguments(arg = "-i") {
    return [arg, this.name];
  }
}

/**
 * @param {File} file
 * @returns {Promise<FfmpegFile>}
 */
FfmpegFile.createFromFile = function (file) {
  return file.arrayBuffer().then((data) => {
    return new FfmpegFile(file.name, data.slice(0));
  });
};
