var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.memos && _vm.memos.length > 0 && !_vm.memoListSortByPlaylistFirst)?_c('div',{staticClass:"d-flex flex-grow-1 align-items-start"},[_c('div',{staticClass:"row w-100",class:{
			'compact-view': _vm.compactView,
			'memos-list': true,
		}},[_vm._l((_vm.memosGroupedByPlaylist),function(playlist,idx){return _c('div',{key:'memosGroupedByPlaylist' + idx,staticClass:"memo-card",class:[_vm.compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2'],on:{"click":function($event){return _vm.openPlaylist(playlist.playlist.id)}}},[_c('MemoGroupCard',{attrs:{"playlist":playlist}})],1)}),_vm._l((_vm.groupByPlaylistView
				? _vm.memosWithoutPlaylist
				: _vm.memos),function(memo,idx){return _c('div',{key:'groupByPlaylistView' + idx,staticClass:"memo-card",class:[_vm.compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2'],on:{"click":function($event){return _vm.openDetails(memo)}}},[_c('MemoCard',{attrs:{"memo":memo}})],1)})],2)]):(_vm.memos && _vm.memos.length > 0)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.selectedPlaylist.name))]),_c('div',{staticClass:"row w-100",class:{
			'compact-view': _vm.compactView,
			'memos-list': true,
		}},_vm._l((_vm.memosFromPlaylist),function(memo,idx){return _c('div',{key:'memosFromPlaylist' + idx,staticClass:"memo-card",class:[_vm.compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2'],on:{"click":function($event){return _vm.openDetails(memo)}}},[_c('MemoCard',{attrs:{"memo":memo}})],1)}),0),_c('h2',{staticClass:"mt-4"},[_vm._v("Others")]),_c('div',{staticClass:"row w-100",class:{
			'compact-view': _vm.compactView,
			'memos-list': true,
		}},[_vm._l((_vm.memosGroupedByPlaylistExceptCurrent),function(playlist,idx){return _c('div',{key:'memosGroupedByPlaylistExceptCurrent' + idx,staticClass:"memo-card",class:[_vm.compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2'],on:{"click":function($event){return _vm.openPlaylist(playlist.playlist.id)}}},[_c('MemoGroupCard',{attrs:{"playlist":playlist}})],1)}),_vm._l((_vm.groupByPlaylistView
				? _vm.memosWithoutPlaylist
				: _vm.otherMemos),function(memo,idx){return _c('div',{key:'groupByPlaylistView' + idx,staticClass:"memo-card",class:[_vm.compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2'],on:{"click":function($event){return _vm.openDetails(memo)}}},[_c('MemoCard',{attrs:{"memo":memo}})],1)})],2)]):_c('div',{staticClass:"center-items flex-column flex-grow-1"},[_c('img',{staticClass:"img-fluid bg-img",attrs:{"src":require("../assets/images/record.jpg")}}),_c('span',{staticClass:"mt-4 text-secondary font-20"},[_vm._v(" Click on the button to start recording new voice memo ")])])}
var staticRenderFns = []

export { render, staticRenderFns }