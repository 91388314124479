// @ts-check
import * as musicMetadata from "music-metadata-browser";

/** @see https://github.com/web-scrobbler/web-scrobbler/blob/ae7f394d3eee08745a0d16562fb4bc8fcfef42ad/src/core/content/util.js */

/**
 * Default array of separators used to split artist and track info.
 * Push new separators in the implementation if required.
 *
 * @type {Array}
 */
const _separators = [
  " -- ",
  "--",
  " ~ ",
  " \u002d ",
  " \u2013 ",
  " \u2014 ",
  " // ",
  "\u002d",
  "\u2013",
  "\u2014",
  ":",
  "|",
  "///",
  "/",
  "~",
];

const ytTitleRegExps = [
  // Artist "Track", Artist: "Track", Artist - "Track", etc.
  {
    pattern: /(.+?)([\s:—-])+\s*"(.+?)"/,
    groups: { artist: 1, track: 3 },
  },
  // Artist「Track」 (Japanese tracks)
  {
    pattern: /(.+?)[『｢「](.+?)[」｣』]/,
    groups: { artist: 1, track: 2 },
  },
  // Track (... by Artist)
  {
    pattern: /(\w[\s\w]*?)\s+\([^)]*\s*by\s*([^)]+)+\)/,
    groups: { artist: 2, track: 1 },
  },
];

/**
 * Find first occurence of possible separator in given string
 * and return separator's position and size in chars or null.
 * @param  {String} str String contains separator
 * @param  {Array} [separators] Array of separators
 * @return {Object} Object contains position and width of separator
 */
function findSeparator(str, separators = null) {
  if (str === null || str.length === 0) {
    return null;
  }

  for (const sep of separators || _separators) {
    const index = str.indexOf(sep);
    if (index > -1) {
      return { index, length: sep.length };
    }
  }

  return null;
}

/**
 * Split string to two ones using array of separators.
 * @param  {String} str Any string
 * @param  {Array} [separators] Array of separators
 * @param  {Boolean} [swap=false] Swap values
 * @return {Array} Array of strings splitted by separator
 */
function splitString(str, separators, { swap = false } = {}) {
  let first = null;
  let second = null;

  if (str) {
    const separator = findSeparator(str, separators);

    if (separator !== null) {
      first = str.substr(0, separator.index);
      second = str.substr(separator.index + separator.length);

      if (swap) {
        [second, first] = [first, second];
      }
    }
  }

  return [first, second];
}

/**
 * Check if given 'artistTrack' object is empty. The object means empty
 * if its 'artist' and 'track' properties are undefined, null or empty.
 * @param  {Object}  artistTrack Object contains artist and track info
 * @return {Boolean} True if object is empty; false otherwise
 */
function isArtistTrackEmpty(artistTrack) {
  return !(artistTrack && artistTrack.artist && artistTrack.track);
}

/**
 * Split string to artist and track.
 * @param  {String} str String contains artist and track
 * @param  {Array} [separators] Array of separators
 * @param  {Boolean} [swap=false] Swap artist and track values
 * @return {Object} Object contains artist and track fields
 */
function splitArtistTrack(str, separators = null, { swap = false } = {}) {
  const [artist, track] = splitString(str, separators, { swap });
  return { artist, track };
}

/**
 * Extract artist and track from Youtube video title.
 * @param  {String} videoTitle Youtube video title
 * @return {Object} Object contains artist and track fields
 */
function processYtVideoTitle(videoTitle) {
  let artist = null;
  let track = null;

  if (!videoTitle) {
    return { artist, track };
  }

  // Remove [genre] or 【genre】 from the beginning of the title
  let title = videoTitle.replace(/^((\[[^\]]+])|(【[^】]+】))\s*-*\s*/i, "");

  // Remove track (CD and vinyl) numbers from the beginning of the title
  title = title.replace(/^\s*([a-zA-Z]{1,2}|[0-9]{1,2})[1-9]?\.\s+/i, "");

  // Remove - preceding opening bracket
  title = title.replace(/-\s*([「【『])/, "$1");

  // 【/(*Music Video/MV/PV*】/)
  title = title.replace(/[(【].*?((MV)|(PV)).*?[】)]/i, "");

  // 【/(東方/オリジナル*】/)
  title = title.replace(/[(【]((オリジナル)|(東方)).*?[】)]/, "");

  // MV/PV if followed by an opening/closing bracket
  title = title.replace(/(MV|PV)([「【『』】」])/i, "$2");

  // MV/PV if ending and with whitespace in front
  title = title.replace(/\s+(MV|PV)$/i, "");

  // Try to match one of the regexps
  for (const regExp of ytTitleRegExps) {
    const artistTrack = title.match(regExp.pattern);
    if (artistTrack) {
      artist = artistTrack[regExp.groups.artist];
      track = artistTrack[regExp.groups.track];
      break;
    }
  }

  // No match? Try splitting, then.
  if (isArtistTrackEmpty({ artist, track })) {
    ({ artist, track } = splitArtistTrack(title));
  }

  // No match? Check for 【】
  if (isArtistTrackEmpty({ artist, track })) {
    const artistTrack = title.match(/(.+?)【(.+?)】/);
    if (artistTrack) {
      artist = artistTrack[1];
      track = artistTrack[2];
    }
  }

  if (isArtistTrackEmpty({ artist, track })) {
    track = title;
  }

  return { artist, track };
}

/**
 *
 * @param {Blob|ReadableStream} data
 * @param {string} audioTitle
 * @returns {Promise<Object>}
 */
async function extractArtistNTrackFromAudioTitle(data, audioTitle) {
  let metadata;

  if (data instanceof Blob) {
    metadata = await musicMetadata.parseBlob(data);
  } else if (data instanceof ReadableStream) {
    metadata = await musicMetadata.parseReadableStream(data);
  } else {
    throw "musicMetadata: unsupported data type";
  }

  let artist;
  if (metadata.common.artist && metadata.common.artist.length) {
    artist = metadata.common.artist;
  }

  let track;
  if (metadata.common.title && metadata.common.title.length) {
    track = metadata.common.title;
  }

  if (!(artist && track)) {
    ({ artist, track } = processYtVideoTitle(audioTitle));
  }

  return { artist, track };
}

export default extractArtistNTrackFromAudioTitle;
