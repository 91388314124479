<template>
	<button
		class="flex-row p-2 m-1 border-0 rounded-lg memo-thumbnail d-flex"
		:title="memo.title"
		@click="handleClick"
	>
		<div>
			<img
				v-if="memo.image"
				:src="memo.image"
				alt="memo-dna"
				style="width: 50px; height: 50px"
				width="50px"
				height="50px"
				class="mr-2 rounded-full memo-dna"
			/>
			<img v-else src="../assets/images/volume.svg" class="volume-icon" />
		</div>

		<div class="text-left text">
			<div>{{ memo.title }}</div>
			<div class="text-muted">
				{{ memo.audio.duration | formatTimeUntilSeconds }}
			</div>
		</div>
	</button>
</template>

<style scoped>
.memo-thumbnail {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
}
@media screen and (min-width: 992px) {
	.memo-thumbnail {
		width: 225px;
		min-width: 225px;
		max-width: 225px;
	}
}

.memo-thumbnail .text {
	overflow: hidden;
}
.memo-thumbnail .text > div {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rounded-full {
	border-radius: 9999px;
}
</style>

<script>
// @ts-check
import { mapMutations } from "vuex";

export default {
	name: "MemoThumbnail",

	props: {
		memo: {
			type: Object,
			required: true,
		},
	},

	methods: {
		...mapMutations("memo", ["setCurrentMemo"]),

		handleClick() {
			this.$emit("click", this);
			this.setCurrentMemo(this.memo);
		},
	},
};
</script>
