// @ts-check

import Memo from "./Memo";
import { MemoAudio } from "./MemoAudio";

export class MemoCropHistory {
  /**
   * @param {Memo} memo
   */
  constructor(memo) {
    this._memo = memo;
    this._currentIndex = undefined;
    this._entries = [];
    this.append(memo.audio);
  }

  /**
   * @returns {Memo}
   */
  get memo() {
    return this._memo;
  }

  /**
   * @returns {number}
   */
  get currentIndex() {
    return this._currentIndex !== undefined
      ? this._currentIndex
      : this._entries.length - 1;
  }

  /**
   * @param {number} current
   * @returns {MemoCropHistory}
   */
  setCurrentIndex(current) {
    this._currentIndex = current;
    return this;
  }

  /**
   * @returns {boolean}
   */
  isLastSelected() {
    return this.currentIndex === this.entries.length - 1;
  }

  /**
   * @returns {CropHistoryEntry[]}
   */
  get entries() {
    return this._entries;
  }

  /**
   * @param {MemoAudio} audio
   * @returns {number}
   */
  append(audio) {
    const index = this._entries.length;
    this._entries.push(new CropHistoryEntry(index, audio, new Date()));
    this.setCurrentIndex(index);
    return index;
  }

  /**
   * @param {MemoAudio} audio
   * @param {number} [atIndex]
   * @returns {number}
   */
  replace(audio, atIndex) {
    if (!atIndex) {
      atIndex = this.currentIndex;
    }

    this._entries = this.entries.slice(0, atIndex + 1);
    return this.append(audio);
  }

  /**
   * @returns {CropHistoryEntry}
   */
  get current() {
    return this._entries[this.currentIndex];
  }
}

export class CropHistoryEntry {
  /**
   * @param {number} index
   * @param {MemoAudio} audio
   * @param {Date} time
   */
  constructor(index, audio, time) {
    this._index = index;
    this._audio = audio;
    this._time = time;
  }

  get index() {
    return this._index;
  }

  get audio() {
    return this._audio;
  }

  get time() {
    return this._time;
  }
}
