<template>
	<div>
		<!-- Normal waveform -->
		<MemoWaveform
			ref="MemoWaveform"
			:memo="memo"
			:height="heigth"
			:opacity="opacity"
			:style="{ display: !memo.isSelectedDenoised ? 'block' : 'none' }"
			v-on="$listeners"
		/>

		<!-- Denoised waveform -->
		<MemoWaveform
			v-if="memo.isSelectedDenoised || denoisedWaveformRendered"
			ref="MemoWaveformDenoised"
			:memo="memo"
			:height="heigth"
			:opacity="opacity"
			:style="{ display: memo.isSelectedDenoised ? 'block' : 'none' }"
			:useDenoisedAudio="true"
			v-on="$listeners"
			@rendered="_handleDenoisedWaveformRendered"
		/>
	</div>
</template>

<script>
// @ts-check
import Memo from "@/lib/Memo";
import MemoWaveform from "./MemoWaveform.vue";

export default {
	components: { MemoWaveform },

	props: {
		memo: {
			type: Memo,
			required: true,
		},
		heigth: {
			default: 150,
		},
		opacity: {
			default: 0.4,
			type: Number,
		},
	},

	data() {
		return {
			denoisedWaveformRendered: false,
		};
	},

	computed: {
		currentWaveform() {
			return this.memo.isSelectedDenoised
				? this.$refs.MemoWaveformDenoised
				: this.$refs.MemoWaveform;
		},
	},

	watch: {
		"memo.isSelectedDenoised"() {
			if (this.currentWaveform) {
				this.currentWaveform.render();
			}
		},
	},

	methods: {
		draw(percent, opacity = 0.8) {
			if (this.currentWaveform) {
				this.currentWaveform.draw(percent, opacity);
			}
		},

		restoreWaveform() {
			if (this.currentWaveform) {
				this.currentWaveform.restoreWaveform();
			}
		},

		_handleDenoisedWaveformRendered() {
			this.denoisedWaveformRendered = true;
		},
	},
};
</script>
