<template>
	<div :height="height + 'px'">
		<canvas
			:height="height + 'px'"
			style="width: 100%"
			class="waveform"
			ref="canvas"
		></canvas>

		<loading-spinner v-if="isLoading"></loading-spinner>
	</div>
</template>

<script>
// @ts-check
import { createWaveform } from "@/lib/Waveform";
import LoadingSpinner from "../LoadingSpinner.vue";
import Memo from "@/lib/Memo";
import { CropHistoryEntry } from "@/lib/MemoCropHistory";
import { MemoAudio } from "@/lib/MemoAudio";

export default {
	components: { LoadingSpinner },

	props: {
		memo: {
			type: Memo,
		},
		cropHistoryEntry: {
			type: CropHistoryEntry,
		},
		useDenoisedAudio: Boolean,
		height: {
			default: 150,
		},
		opacity: {
			default: 0.4,
			type: Number,
		},
	},

	data() {
		return {
			waveform: null,
			/** @type {MemoAudio} */
			audio: null,
			isLoading: true,
		};
	},

	watch: {
		memo() {
			this.render();
		},
		cropHistoryEntry() {
			this.render();
		},
	},

	methods: {
		render() {
			this.isLoading = true;

			if (this.memo) {
				this.audio = this.memo.audio;
			} else if (this.cropHistoryEntry) {
				this.audio = this.cropHistoryEntry.audio;
			}

			let decodedDataPromise;
			if (this.useDenoisedAudio) {
				decodedDataPromise = this.audio.denoise();
			} else {
				decodedDataPromise = this.audio.decode();
			}

			if (decodedDataPromise) {
				decodedDataPromise.then(createWaveform).then((waveform) => {
					this.waveform = waveform;
					this.waveform.initCanvas(this.$refs.canvas);
					this.waveform.draw(1, this.opacity);
					this.isLoading = false;
					this.$emit("rendered", this.waveform);
				});
			}
		},

		restoreWaveform() {
			this.waveform.draw(1, this.opacity);
		},

		draw(percent, opacity = 0.8) {
			if (this.waveform) {
				this.waveform.draw(percent, opacity);
			}
		},
	},

	mounted() {
		this.$nextTick(() => this.render());
	},
};
</script>
