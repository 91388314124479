// @ts-check

import { isResponseOk } from "@/api/utils";
import { MemoAudio } from "./MemoAudio";
import { uid } from "uid";

const DEFAULT_TITLE = "Untitled Memo";

export default class Memo {
  /** @type {string|undefined} */
  _id;

  _localId = uid();

  /** @type {MemoAudio} */
  _audio;

  /** @type {string|undefined} */
  _title;

  /** @type {string|undefined} */
  _description;

  /** @type {Date|undefined} */
  _updatedAt;

  /** @type {boolean|undefined} */
  _isSelectedDenoised;

  /** @type {Blob|undefined} */
  imageBlob;

  /** @type {string|undefined} */
  _image;

  /** @type {string[]} */
  _playlists;

  /**
   * @param {MemoAudio} [memoAudio]
   * @param {{imageBlob?: Blob}} [props]
   */
  constructor(memoAudio, props = { imageBlob: undefined }) {
    this._id = undefined;
    this._localId = uid();
    this._audio = memoAudio;
    this._title = "";
    this._description = "";
    this._updatedAt = new Date();
    this._isSelectedDenoised = false;
    this.setImageFrom(props.imageBlob);
    this._playlists = [];
  }

  get id() {
    return this._id;
  }

  get localId() {
    return this._localId;
  }

  get audio() {
    return this._audio;
  }

  /**
   * @param {MemoAudio} newAudio
   * @returns {Memo}
   */
  setAudio(newAudio) {
    this._audio = newAudio;
    return this;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    if (title === "") {
      title = DEFAULT_TITLE;
    }

    this._title = title;
    this._updatedAt = new Date();
  }

  get description() {
    return this._description;
  }

  set description(description) {
    this._description = description?.trim();
    this._updatedAt = new Date();
  }

  get updatedAt() {
    return this._updatedAt;
  }

  get isSelectedDenoised() {
    return this._isSelectedDenoised;
  }

  set isSelectedDenoised(value) {
    this._isSelectedDenoised = value;
  }

  get image() {
    return this._image;
  }

  set image(value) {
    this._image = value;
  }

  /**
   * @param {Blob} imageBlob
   */
  setImageFrom(imageBlob) {
    if (this._image) URL.revokeObjectURL(this._image);

    this.imageBlob = imageBlob;
    this._image =
      this.imageBlob instanceof Blob
        ? URL.createObjectURL(this.imageBlob)
        : undefined;
  }

  get playlists() {
    return this._playlists;
  }

  destroy() {
    if (this._image) URL.revokeObjectURL(this._image);
    if (this.audio) this.audio.destroy();
  }
}

/**
 * @param {object} obj
 * @returns {Memo}
 */
Memo.fromObject = function (obj) {
  const memo = new Memo();

  memo._id = obj.id;
  memo.title = obj.title;
  memo.description = obj.description;
  memo.isSelectedDenoised = obj.is_selected_denoised;

  // Process audio
  const audio_url = obj.audio_url;
  if (!audio_url || typeof audio_url !== "string" || audio_url.length === 0) {
    throw new Error("Missing audio_url");
  }

  const audio_type = obj.audio_type;
  if (
    !audio_type ||
    typeof audio_type !== "string" ||
    audio_type.length === 0
  ) {
    throw new Error("Missing audio_type");
  }

  fetch(audio_url, { method: "GET" })
    .then(isResponseOk)
    .then((res) => res.arrayBuffer())
    .then((arrayBuffer) => new Blob([arrayBuffer], { type: audio_type }))
    .then((blob) =>
      memo.setAudio(new MemoAudio(blob, undefined, memo.isSelectedDenoised))
    );

  // Process image
  memo.image = obj.image_url;

  memo._playlists = obj.playlists;

  return memo;
};
