// @ts-check

import { BASEPATH, isResponseOk } from "./utils";

export function login() {
  return fetch(BASEPATH + "/v1/login", {
    method: "GET",
    credentials: "include",
  })
    .then(isResponseOk)
    .then((res) => res.json());
}
