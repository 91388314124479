import { AbstractSpectrumVisualization } from "./AbstractSpectrumVisualization";
import { AbstractVisualization } from "./AbstractVisualization";
import { AudioVisualizer } from "./AudioVisualizer";
import { DnaVisualizationLOGGER } from "./debug";

const WorkerEventType = {
  INIT: 0,
  DATA: 1,
  RENDER: 2,
  TEST_RENDER: 3,
  RENDERED: 4,
  GET_IMAGE_BLOB: 5,
  IMAGE_BLOB_RESPONSE: 6,
  STOP: 7,
  ERROR: 8,
};
Object.freeze(WorkerEventType);

/**
 * @property {import("./AudioVisualizer").VisualizationOptions}} options
 */
export class DnaVisualization extends AbstractSpectrumVisualization {
  /** @property {Uint8Array[]} */
  datas = [];
  /** @property {number} */
  scale = 1.0;
  /** @property {Worker|undefined} */
  worker = undefined;

  /**
   * @param {AudioVisualizer} audioVisualizer
   * @param {import("./AudioVisualizer").VisualizationOptions & {scale: number}} [options]
   */
  constructor(audioVisualizer, options = {}) {
    super(audioVisualizer, options);

    this.newLineCnv.width = 1;
    this.newLineCnv.height = this.audioVisualizer.bufferLength;
    this.newLineImageData = new ImageData(
      new Uint8ClampedArray(
        new ArrayBuffer(this.audioVisualizer.bufferLength * 4)
      ),
      1,
      this.audioVisualizer.bufferLength
    );

    this.scale = options.scale || 1.0;

    this.tryInitializeWorker();
  }

  tryInitializeWorker() {
    if (
      typeof this.cnv.transferControlToOffscreen === "function" &&
      "OffscreenCanvas" in window
    ) {
      this.worker = new Worker("dna-offscreen.js");
      this.cnv = this.cnv.transferControlToOffscreen();
      this.worker.postMessage(
        [
          WorkerEventType.INIT,
          {
            canvas: this.cnv,
            bufferLength: this.audioVisualizer.bufferLength,
            colormap: this.colormap,
            scale: this.scale,
          },
        ],
        [this.cnv]
      );
    }
  }

  /**
   * @returns {HTMLCanvasElement|OffscreenCanvas}
   */
  getCanvas() {
    return this.cnv;
  }

  /**
   * Draws the spectrum (waterfall graph).
   * @param {{elapsed: number}} props
   */
  update(props = { elapsed: 0 }) {
    if (this.isStopped) return;

    let rowsPerSec =
      this.options.rowsPerSec ||
      AbstractVisualization.DefaultOptions.rowsPerSec;

    this.lag += rowsPerSec * (props.elapsed / 1000);
    if (this.lag <= 1) return;

    let rowsToRender = Math.floor(this.lag);
    this.lag -= rowsToRender;

    this.audioVisualizer.analyser.getByteFrequencyData(
      this.audioVisualizer.dataArray
    );

    const data = this.audioVisualizer.dataArray.slice();
    if (this.worker) {
      this.worker.postMessage([WorkerEventType.DATA, data], [data.buffer]);
    } else {
      this.datas.push(data);
    }
  }

  render() {
    DnaVisualizationLOGGER("render");
    if (this.isStopped) return Promise.reject("Visualization is stopped!");

    if (this.worker) {
      DnaVisualizationLOGGER("render:worker");
      return new Promise((resolve, reject) => {
        this.worker.onmessage = (event) => {
          DnaVisualizationLOGGER("render:worker:onmessage: %O", event);
          if (event.data[0] === WorkerEventType.RENDERED) {
            resolve();
          } else {
            console.warn(event);
            reject(event);
          }
        };
        DnaVisualizationLOGGER("render:worker:postMessage: %O", [
          WorkerEventType.RENDER,
        ]);
        this.worker.postMessage([WorkerEventType.RENDER]);
      });
    }

    return Promise.resolve().then(() => {
      DnaVisualizationLOGGER("render:regular");
      const len = this.datas.length;

      const x = (r, i) => r * Math.cos((i * 2 * Math.PI) / len);
      const y = (r, i) => r * Math.sin((i * 2 * Math.PI) / len);

      let w = this.cnv.width;
      let h = this.cnv.height;

      // for each data sample
      for (let i = 0; i < len; ++i) {
        if (this.isStopped) return;

        const data = this.datas[i];

        for (
          let j = 0, di = 0;
          j < this.audioVisualizer.bufferLength * 4;
          j += 4, di++
        ) {
          if (this.isStopped) return;

          const mappedColor = this.colormap[data[di]];
          this.newLineImageData.data[j] = mappedColor[0];
          this.newLineImageData.data[j + 1] = mappedColor[1];
          this.newLineImageData.data[j + 2] = mappedColor[2];
          this.newLineImageData.data[j + 3] = 255;
        }
        this.newLineCtx.putImageData(this.newLineImageData, 0, 0);

        // for each row in data sample (radius)
        for (let j = 0; j < this.audioVisualizer.bufferLength; ++j) {
          // filling extra space with radius getting bigger
          for (let k = i; k < i + 1; k += 0.25) {
            if (this.isStopped) return;

            this.ctx.drawImage(
              this.newLineCnv,
              0, // sx
              j, // sy
              1, // sWidth
              1, // sHeight
              w / 2 + x(j, k) * this.scale, // dx
              h / 2 + y(j, k) * this.scale, // dy
              1, // dWidth
              1 // dHeight
            );
          }
        }
      }

      return this;
    });
  }

  _testRender() {
    if (this.isStopped) return;

    const len = 360;

    const x = (r, i) => r * Math.cos((i * 2 * Math.PI) / len);
    const y = (r, i) => r * Math.sin((i * 2 * Math.PI) / len);

    let w = this.cnv.width;
    let h = this.cnv.height;
    // console.log(this.cnv, w, h);

    for (
      let i = 0, di = 0;
      i < this.audioVisualizer.bufferLength * 4;
      i += 4, di++
    ) {
      if (this.isStopped) return;

      this.newLineImageData.data[i] = Math.round(Math.random() * 255);
      this.newLineImageData.data[i + 1] = Math.round(Math.random() * 255);
      this.newLineImageData.data[i + 2] = Math.round(Math.random() * 255);
      this.newLineImageData.data[i + 3] = 255;
    }
    this.newLineCtx.putImageData(this.newLineImageData, 0, 0);

    for (let i = 0; i < len; ++i) {
      for (let j = 0; j < this.audioVisualizer.bufferLength; ++j) {
        for (let k = i; k < i + 1; k += 0.25) {
          if (this.isStopped) return;

          this.ctx.drawImage(
            this.newLineCnv,
            0, // sx
            j, // sy
            1, // sWidth
            1, // sHeight
            w / 2 + x(j, k) * this.scale, // dx
            h / 2 + y(j, k) * this.scale, // dy
            1, // dWidth
            1 // dHeight
          );
        }
      }
    }
  }

  asImageBlob() {
    DnaVisualizationLOGGER("asImageBlob");
    return new Promise((resolve, reject) => {
      this.worker.onmessage = (event) => {
        DnaVisualizationLOGGER("asImageBlob:onmessage: %O", event);
        if (event.data[0] === WorkerEventType.IMAGE_BLOB_RESPONSE) {
          resolve(new Blob([event.data[2]], { type: event.data[1] }));
        } else {
          console.warn(event);
          reject(event);
        }
        this.worker.terminate();
        this.worker = undefined;
      };
      DnaVisualizationLOGGER("asImageBlob:postMessage: %O", [
        WorkerEventType.GET_IMAGE_BLOB,
      ]);
      this.worker.postMessage([WorkerEventType.GET_IMAGE_BLOB]);
    });
  }

  stop() {}

  forceStop() {
    DnaVisualizationLOGGER("forceStop");
    if (this.worker) {
      this.worker.postMessage([WorkerEventType.STOP]);
    } else {
      this._isStopped = true;
    }
  }
}
