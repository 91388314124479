// @ts-check

import { ffmpeg } from "./ffmpeg";
import { FfmpegFile } from "./FfmpegFile";

/**
 * @param {FfmpegFile|File} file
 * @param {string} fileExtension
 * @param {{outCodec?: string}} [props]
 * @returns {Promise<import("./ffmpeg").FfmpegResponseDone<ArrayLike>>}
 */
export function ffmpegConvert(file, fileExtension, props = {}) {
  return ffmpeg(runCb.bind(undefined, file, fileExtension, props));
}

/**
 * @param {FfmpegFile|File} file
 * @param {string} fileExtension
 * @param {{outCodec?: string}} props
 * @param {Worker} worker
 */
function runCb(file, fileExtension, props, worker) {
  const outCodec = props.outCodec;

  if (file instanceof AudioBuffer) {
    const buff = file.getChannelData(0).slice(0).buffer;

    let outputEncoder = [];
    if (outCodec) {
      outputEncoder = ["-codec:a", outCodec];
    }

    const args = [
      "-y",
      "-f",
      "f32le",
      "-i",
      "input.pcm",
      "-vn",
      ...outputEncoder,
      "output." + fileExtension,
    ];
    console.log("ffmpegConvert::runCb:args:", args.join(" "));

    worker.postMessage(
      {
        type: "run",
        MEMFS: [{ name: "input.pcm", data: buff }],
        arguments: args,
      },
      [buff]
    );
  } else if (file instanceof File) {
    FfmpegFile.createFromFile(file).then((file) => {
      let outputEncoder = [];
      if (outCodec) {
        outputEncoder = ["-codec:a", outCodec];
      }

      console.log(file);

      const args = [
        "-y",
        // "-f",
        // "f32le",
        // "-i",
        // "input.pcm",
        ...file.getArguments(),
        "-vn",
        ...outputEncoder,
        "output." + fileExtension,
      ];
      console.log("ffmpegConvert::runCb:args:", args.join(" "));

      const message = {
        type: "run",
        MEMFS: [file],
        arguments: args,
      };

      console.log("ffmpegConvert::runCb:message:", message);

      worker.postMessage(message, [file.data]);
    });
  }
}
