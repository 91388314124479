// @ts-check

const state = {
  currentUser: undefined,
};

const getters = {
  /**
   * @param {Object} state
   * @returns {Object|undefined}
   */
  currentUser: (state) => state.currentUser,
};

const mutations = {
  /**
   * @param {Object} state
   * @param {Object} newUser
   */
  setCurrentUser(state, newUser) {
    state.currentUser = newUser;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
