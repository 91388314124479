<template>
	<b-card class="row">
		<div class="row">
			<img
				v-if="memo.image"
				:src="memo.image"
				alt="memo-dna"
				style="width: 100px; height: 100px; object-fit: cover"
				width="100px"
				height="100px"
				class="mb-2 rounded-full memo-dna"
			/>
			<img v-else src="../assets/images/volume.svg" class="volume-icon" />
		</div>

		<div class="row">
			<span>{{ memo.title }}</span>

			<span v-if="memo.audio && memo.audio.duration != undefined">
				{{ memo.audio.duration | formatTimeUntilSeconds }}
			</span>
			<span v-else>Loading time...</span>

			<span>{{ memo.updatedAt | formatDate }}</span>

			<b-icon
				v-if="memo.id"
				class="icon playlist-icon"
				icon="collection-play"
				variant="primary"
				title="Playlist"
				ref="playlist-btn"
				@click="openPlaylistModal"
			></b-icon>

			<div
				v-if="!memo.id"
				class="upload-icon"
				v-on:click.stop="uploadHandler(memo)"
				title="Upload"
			></div>
			<div
				class="delete-icon text-danger"
				v-on:click.stop="deleteHandler(memo)"
				title="Delete"
			></div>
		</div>
	</b-card>
</template>

<style scoped>
.rounded-full {
	border-radius: 9999px;
}
</style>

<script>
// @ts-check
import { newMemo, deleteMemo } from "@/api/memo";
import { mapMutations } from "vuex";

export default {
	name: "MemoCard",

	props: {
		memo: {
			type: Object,
			required: true,
		},
	},

	methods: {
		...mapMutations("memo", ["deleteMemo", "setCurrentMemo"]),
		...mapMutations("memo", ["deleteMemo"]),
		...mapMutations("view", ["setMemoAddToPlaylistView"]),

		async uploadHandler(memo) {
			this.$swal.fire({
				icon: "warning",
				title: "Saving",
			});

			await newMemo(memo)
				.then((data) => {
					memo._id = data.id;
					this.$swal.fire({
						icon: "success",
						title: "Saved",
						timer: 2000,
						timerProgressBar: true,
					});
				})
				.catch((err) => {
					console.error(err);
					this.$swal.fire({
						icon: "error",
						title: "Failed to save",
						timer: 4000,
						timerProgressBar: true,
					});
				});
		},

		deleteHandler(memo) {
			this.$swal.fire({
				icon: "warning",
				title: "Deleting",
			});

			if (memo.id) {
				deleteMemo(memo)
					.then(() => {
						this.deleteMemo(memo);

						this.$swal.fire({
							icon: "success",
							title: "Deleted",
							timer: 2000,
							timerProgressBar: true,
						});
					})
					.catch((err) => {
						console.error(err);
						this.$swal.fire({
							icon: "error",
							title: "Failed to delete",
							timer: 4000,
							timerProgressBar: true,
						});
					});
			} else {
				this.deleteMemo(memo);

				this.$swal.fire({
					icon: "success",
					title: "Deleted",
					timer: 2000,
					timerProgressBar: true,
				});
			}
		},

		openPlaylistModal(event) {
			event.preventDefault();
			event.stopPropagation();
			this.setCurrentMemo(this.memo);
			this.setMemoAddToPlaylistView(true);
		},
	},
};
</script>
