// @ts-check

/**
 * @typedef {Int8Array|Uint8Array|Uint8ClampedArray|Int16Array|Uint16Array|Int32Array|Uint32Array|Float32Array|Float64Array|BigInt64Array|BigUint64Array|ArrayLike<number>|ArrayLike<bigint>} TypedArray
 * @typedef {Int8ArrayConstructor|Uint8ArrayConstructor|Uint8ClampedArrayConstructor|Int16ArrayConstructor|Uint16ArrayConstructor|Int32ArrayConstructor|Uint32ArrayConstructor|Float32ArrayConstructor|Float64ArrayConstructor|BigInt64ArrayConstructor|BigUint64ArrayConstructor} TypedArrayConstructor
 */

/**
 * @param {TypedArray[]} arrays
 * @returns {AudioBuffer}
 */
export function toAudioBuffer(arrays) {
  const audioContext = getNewAudioContext();
  const audioBuffer = audioContext.createBuffer(
    arrays.length, // number of channels
    arrays[0].length, // audiobuffer length
    48000
  );
  for (let i = 0; i < arrays.length; ++i) {
    audioBuffer.copyToChannel(arrays[i], i);
  }
  return audioBuffer;
}

/**
 * @param {TypedArray} a
 * @param {TypedArray} b
 * @param {TypedArrayConstructor} type
 * @returns {TypedArray}
 */
export function concatTypedArrays(a, b, type) {
  const c = new type(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
}

/**
 * @returns {new (contextOptions?: AudioContextOptions): AudioContext}
 */
export function getAudioContext() {
  return window.AudioContext || window.webkitAudioContext;
}

/**
 * @returns {AudioContext}
 */
export function getNewAudioContext() {
  return new (getAudioContext())();
}

/**
 * @see https://aerotwist.com/lab/music-dna/
 * @returns {AnalyserNode}
 */
export function getNewAudioAnalyzer() {
  // Create the context
  var audioContext = getNewAudioContext();

  // Now create our nodes
  var analyser = audioContext.createAnalyser();
  var sourceNode = audioContext.createBufferSource();

  // Then hook them together
  sourceNode.connect(analyser);
  analyser.connect(audioContext.destination);

  return analyser;
}
