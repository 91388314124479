<template>
	<div class="memo-regular-view">
		<div class="shadow-lg memo-dna-wrapper position-relative">
			<img
				class="memo-dna position-absolute top-left-0 w-100"
				:src="memo.image"
			/>

			<div class="position-absolute top-left-0">
				<h3 class="p-2 mx-2 mt-2 mb-0 title-wrapper">
					{{ memo.title }}
				</h3>

				<h6 class="p-2 mx-2 mt-1 d-inline-block title-wrapper">
					{{ memo.audio.duration | formatTimeUntilSeconds }}
				</h6>
			</div>

			<MemoPlayer ref="MemoPlayer" :memo="memo" @ended="handleAudioEnded" />

			<b-button
				class="m-2 shadow-lg position-absolute bottom-left-0"
				variant="primary"
				@click="togglePlay"
			>
				<b-icon
					:class="'h2' + (isPlaying ? ' playing' : '')"
					:icon="isPlaying ? 'pause' : 'play'"
				/>
			</b-button>
		</div>

		<div class="p-3 px-4" v-if="memo.description">
			<h6>Description</h6>
			<div class="description-body" v-html="memo.description"></div>
		</div>
	</div>
</template>

<style scoped>
.memo-regular-view {
	overflow: hidden;
	border-radius: 15px;
}

.memo-regular-view .memo-dna-wrapper {
	height: 20rem;
	border-radius: 15px;
}

.memo-regular-view .memo-dna-wrapper .memo-dna {
	object-fit: cover;
	height: 100%;
	border-radius: 15px;
}

.memo-regular-view .memo-dna-wrapper .title-wrapper {
	color: white;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
}

.memo-regular-view .memo-dna-wrapper .btn {
	width: 45px;
	height: 45px;
	border-radius: 9999px;
}
.memo-regular-view .memo-dna-wrapper .btn svg {
	margin-left: -0.275rem;
}
.memo-regular-view .memo-dna-wrapper .btn svg.playing {
	margin-left: -0.375rem;
}

.top-left-0 {
	top: 0;
	left: 0;
}
.bottom-left-0 {
	bottom: 0;
	left: 0;
}

.description-body {
	white-space: pre-line;
}
</style>

<script>
// @ts-check
import Memo from "@/lib/Memo";
import MemoPlayer from "../../MemoPlayer.vue";

export default {
	components: { MemoPlayer },

	props: {
		memo: {
			type: Memo,
			required: true,
		},
	},

	data() {
		return {
			/** @type {boolean} */
			isPlaying: false,
		};
	},

	methods: {
		handleAudioEnded() {
			this.isPlaying = false;
		},

		togglePlay() {
			if (this.isPlaying) {
				this.$refs.MemoPlayer.pause();
			} else {
				this.$refs.MemoPlayer.play();
			}
			this.isPlaying = !this.isPlaying;
		},
	},
};
</script>
