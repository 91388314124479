<template>
	<svg class="progress-ring" :width="size" :height="size">
		<circle
			ref="circle"
			cx="50%"
			cy="50%"
			fill="transparent"
			class="progress-ring__circle"
			dominant-baseline="middle"
			text-anchor="middle"
			:stroke="color"
			:stroke-width="thickness"
			:r="size / 2 - thickness"
		/>

		<text
			x="50%"
			y="52.75%"
			font-size="smaller"
			dominant-baseline="middle"
			text-anchor="middle"
			:fill="textColor"
		>
			{{ text }}
		</text>

		<title>{{ title }}</title>
	</svg>
</template>

<style scoped>
.progress-ring__circle {
	transition: 0.35s stroke-dashoffset;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}
</style>

<script>
// @ts-check

export default {
	props: {
		text: {
			type: String,
		},
		title: {
			type: String,
		},
		size: {
			type: Number,
			default: 50,
		},
		progress: {
			type: Number,
			default: 0.1,
		},
		color: {
			type: String,
			default: "red",
		},
		textColor: {
			type: String,
			default: "red",
		},
		thickness: {
			type: Number,
			default: 4,
		},
	},

	data() {
		return {
			circumference: 0,
		};
	},

	methods: {
		setProgress(progress) {
			const offset = this.circumference - progress * this.circumference;
			this.$refs.circle.style.strokeDashoffset = offset;
		},
	},

	watch: {
		progress(progress) {
			this.setProgress(progress);
		},
	},

	mounted() {
		let radius = this.$refs.circle.r.baseVal.value;
		this.circumference = radius * 2 * Math.PI;

		this.$refs.circle.style.strokeDasharray =
			this.circumference + " " + this.circumference;
		this.$refs.circle.style.strokeDashoffset = this.circumference;

		this.setProgress(this.progress);
	},
};
</script>
