<template>
	<b-row class="bg-primary py-3">
		<b-col cols="5" class="d-flex">
			<b-button
				size="lg"
				variant="light"
				class="btn center-items mr-4"
				@click="$emit('zoom-out')"
			>
				<b-icon icon="zoom-out" variant="primary" class="font-20"></b-icon>
			</b-button>

			<b-button
				size="lg"
				variant="light"
				class="btn center-items mr-4"
				@click="$emit('zoom-in')"
			>
				<b-icon icon="zoom-in" variant="primary" class="font-20"></b-icon>
			</b-button>

			<b-button
				size="lg"
				variant="light"
				class="btn center-items mr-4"
				@click="$emit('zoom-full')"
			>
				<b-icon
					icon="arrows-fullscreen"
					variant="primary"
					class="font-20"
				></b-icon>
			</b-button>
		</b-col>

		<b-col cols="2" class="d-flex justify-content-center">
			<b-button
				size="lg"
				variant="light"
				class="btn center-items"
				@click="playing ? $emit('pause') : $emit('play')"
			>
				<b-icon
					:icon="playing ? 'pause-fill' : 'play-fill'"
					variant="primary"
					class="font-20"
				></b-icon>
			</b-button>
		</b-col>

		<b-col cols="5">
			<b-row>
				<b-col class="ml-auto pr-0" cols="10">
					<b-row class="d-flex flex-row flex-nowrap">
						<b-form-input
							class="border-0"
							v-model="cropFromStr"
							placeholder="Cut From"
							title="Cut From"
						></b-form-input>

						<div
							class="px-2 text-white d-flex align-items-center font-weight-bolder"
						>
							&mdash;
						</div>

						<b-form-input
							class="border-0"
							v-model="cropToStr"
							placeholder="Cut To"
							title="Cut To"
						></b-form-input>
					</b-row>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<style scoped>
.label {
	min-width: 70px;
}
</style>

<script>
// @ts-check

/**
 * @param {string} value
 * @returns {number}
 */
function toTenth(value) {
	let result = "1";
	let i = 0;
	for (; i < value.length; ++i) {
		result += "0";
	}
	return +result;
}

export default {
	name: "Controls",
	props: {
		playing: {
			type: Boolean,
		},
		cropFrom: Number,
		cropTo: Number,
	},

	data() {
		return {
			zoomSeconds: 0,
		};
	},

	computed: {
		cropFromStr: {
			get() {
				return this.format(this.cropFrom);
			},
			set(value) {
				this.$emit("crop-from-updated", value ? this.parse(value) : 0);
			},
		},
		cropToStr: {
			get() {
				return this.format(this.cropTo);
			},
			set(value) {
				this.$emit("crop-to-updated", value ? this.parse(value) : 0);
			},
		},
	},

	methods: {
		/** @param {number} time */
		format(time) {
			return this.$options.filters.formatTimeUntilMilliseconds(time * 1000);
		},

		/** @param {string} timeStr */
		parse(timeStr) {
			let minutes, seconds, milliseconds, rest;
			[minutes, rest] = timeStr.split(":");
			[seconds, milliseconds] = rest.split(".");
			let time =
				+minutes * 60 +
				+seconds +
				(milliseconds ? +milliseconds / toTenth(milliseconds) : 0);
			return time;
		},
	},
};
</script>
