<template>
	<b-button
		variant="primary"
		size="lg"
		pill
		v-on:click="uploadMemoClickHandler"
		class="p-2 mr-4 display-6 position-relative"
	>
		<b-icon class="cloud-upload" icon="cloud-upload"></b-icon>

		<input type="file" class="d-none" ref="file" accept="audio/*" />

		<MemoDnaRenderQueue ref="memoDnaRenderQueue" />
	</b-button>
</template>

<style lang="sass" scoped>
button
	width: 65px
	height: 65px
</style>

<script>
// @ts-check
import { mapMutations } from "vuex";
import { updateImage } from "@/api/memo";
import Memo from "@/lib/Memo";
import { MemoAudio } from "@/lib/MemoAudio";
import { DnaVisualization, getNewAudioVisualizer } from "@/lib/AudioVisualizer";
import MemoDnaRenderQueue from "./MemoDnaRenderQueue.vue";
import { createUrlFrom } from "@/lib/utils";

export default {
	components: { MemoDnaRenderQueue },

	methods: {
		...mapMutations("memo", ["setCurrentMemo"]),

		uploadMemoClickHandler() {
			this.$refs.file.click();
		},

		async uploadFile(file) {
			// create new memo
			const memoAudio = new MemoAudio(file);
			const newMemo = new Memo(memoAudio);
			newMemo._fileName = file.name;

			this.$refs.file.value = null;

			const url = createUrlFrom(file);
			const audioVisualizer = await getNewAudioVisualizer({
				src: url,
			});
			// newMemo._audioVisualizer = audioVisualizer;
			const visualization = new DnaVisualization(audioVisualizer, {
				scale: 0.1,
			});
			audioVisualizer.addVisualization(visualization);

			this.$refs.memoDnaRenderQueue
				.push(newMemo, audioVisualizer)
				.then((data) => {
					if (newMemo.id) {
						updateImage(newMemo);
					} else {
						newMemo.setImageFrom(data.blob);
					}
				});

			this.setCurrentMemo(newMemo);
			this.$emit("upload");
		},
	},

	mounted() {
		this.$refs.file.addEventListener("change", () => {
			if (this.$refs.file.files.length) {
				const file = this.$refs.file.files[0];
				this.uploadFile(file);
			}
		});
	},
};
</script>
