import { defaultLogger } from "../debug";

export const AudioVisualizerLOGGER = defaultLogger.extend("AudioVisualizer");

export const VisualizationLOGGER = AudioVisualizerLOGGER.extend(
  "Visualization"
);

export const SpectrumVisualizationLOGGER = VisualizationLOGGER.extend(
  "Spectrum"
);

export const WaveformVisualizationLOGGER = VisualizationLOGGER.extend(
  "Waveform"
);

export const DnaVisualizationLOGGER = VisualizationLOGGER.extend("Dna");
