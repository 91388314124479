// @ts-check

/**
 * @param {number} nSeconds
 * @returns {string}
 */
export function toHHMMSS(nSeconds) {
  /** @type {number|string} */
  let hours = Math.floor(nSeconds / 3600);
  /** @type {number|string} */
  let minutes = Math.floor((nSeconds - hours * 3600) / 60);
  /** @type {number|string} */
  let seconds = nSeconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds.toFixed(2);
  }

  return hours + ":" + minutes + ":" + seconds;
}

/**
 * @returns {{promise: Promise, resolve: Function, reject: (reason: any) => PromiseLike<never>}}
 */
export function createUnwrappedPromise() {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve, reject };
}

/**
 * @param {string} type
 * @returns {string}
 */
function extractExtensionFromBlobType(type) {
  const result = /^(.*)\/([\w\d]*)/gm.exec(type);
  console.log(result);
  return result[2];
}

/**
 * @param {string} fileName
 * @returns {string}
 */
export function extractExtensionFromFileName(fileName) {
  return fileName.split(".").pop();
}

/**
 * @param {Blob} blob
 * @param {string} fileName
 * @param {string|null} [extension]
 * @param {FilePropertyBag} [options]
 * @returns {File}
 */
export function blobToFile(blob, fileName, extension = null, options = {}) {
  console.log(blob, fileName);
  const fullFileName =
    fileName +
    "." +
    (extension ? extension : extractExtensionFromBlobType(blob.type));

  return new File([blob], fullFileName, options);
}

/**
 * @param {BlobPart[]} blobParts
 * @param {BlobPropertyBag} blobOptions
 */
export function createBlobFrom(
  blobParts,
  blobOptions = { type: "audio/webm;codecs=opus" }
) {
  try {
    return new Blob(blobParts, blobOptions);
  } catch (e) {
    console.error("Error while creating new Blob: ", e);
    return null;
  }
}

/**
 * @param {Blob} blob
 * @returns {string}
 */
export function createUrlFrom(blob) {
  try {
    return URL.createObjectURL(blob);
  } catch (e) {
    console.error("Error while creating new Object url: ", e);
    return null;
  }
}
