<template>
	<div class="center-items flex-column">
		<div class="mb-4 font-30">Record a new memo</div>

		<div class="rounded w-100">
			<record-volume-bars :bus="bus"></record-volume-bars>
		</div>

		<span class="my-2 font-30">{{
			recordDuration | formatTimeUntilMilliseconds
		}}</span>

		<div class="buttons w-100 position-relative">
			<div
				v-if="this.recordingProccessStarted"
				class="btn-left center-items h-100"
			>
				<b-button
					pill
					variant="primary"
					size="md"
					class="pause-btn center-items"
					@click="togglePauseRecording"
					:title="recordingProccessPaused ? 'Resume' : 'Pause'"
				>
					<b-icon
						v-if="recordingProccessPaused"
						style="width: 2rem; height: 2rem"
						icon="play"
						variant=""
						class="icon"
					></b-icon>
					<b-icon
						v-else
						style="width: 2rem; height: 2rem"
						icon="pause"
						variant=""
						class="icon"
					></b-icon>
				</b-button>
			</div>

			<div class="btn-center">
				<b-button
					pill
					:title="
						this.recordingProccessStarted ? 'Stop and save' : 'Start recording'
					"
					variant="primary"
					size="lg"
					class="my-5 play-btn center-items"
					:disabled="toggleRecordingBtnDisabled"
					@click="toggleRecording"
				>
					<div
						:class="{
							active: this.recordingProccessStarted,
							'content rounded-pill': true,
						}"
					></div>
				</b-button>
			</div>
		</div>

		<MemoDnaRenderQueue ref="memoDnaRenderQueue" />
	</div>
</template>

<style scoped>
.buttons {
	height: 150px;
}
.buttons .btn-left {
	position: absolute;
	top: 0;
	left: calc(50% - (56px + 1rem));
	transform: translateX(-50%);
}
.buttons .btn-center {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.rounded {
	border-radius: 2rem;
	overflow: hidden;
}
</style>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import {
	getNewMediaRecorder,
	MediaRecorderWrapper,
} from "../lib/MediaRecorder";
import { Timer } from "../lib/Timer";
import RecordVolumeBars from "./RecordVolumeBars.vue";
import Memo from "@/lib/Memo";
import { MemoAudio } from "@/lib/MemoAudio";
import {
	DnaVisualization,
	getNewAudioVisualizer,
	AudioVisualizer,
} from "@/lib/AudioVisualizer";
import { updateImage } from "@/api/memo";
import MemoDnaRenderQueue from "./MemoDnaRenderQueue.vue";

export default {
	props: ["open", "modalShow"],

	components: {
		RecordVolumeBars,
		MemoDnaRenderQueue,
	},

	data() {
		return {
			/** @type {number} in milliseconds */
			recordDuration: 0,

			/** @type {Timer} */
			timer: new Timer(),

			recordingProccessStarted: false,
			recordingProccessPaused: false,

			/** @type {MediaRecorderWrapper} */
			mediaRecorder: null,

			bus: new Vue(),

			/** @type {AudioVisualizer|undefined} */
			audioVisualizer: undefined,

			/** @type {Promise|undefined} */
			memoDnaPromise: undefined,

			/** @type {boolean} */
			toggleRecordingBtnDisabled: false,
		};
	},

	computed: {
		...mapGetters("memo", ["currentMemo"]),
	},

	methods: {
		...mapMutations("memo", ["setCurrentMemo"]),

		togglePauseRecording() {
			if (!this.mediaRecorder) return;
			if (this.recordingProccessPaused) {
				this.recordingProccessPaused = false;
				this.mediaRecorder.resume();
			} else {
				this.recordingProccessPaused = true;
				this.mediaRecorder.pause();
			}
		},

		stopRecording() {
			this.recordingProccessStarted = false;
			this.timer.stop();
			if (this.mediaRecorder) {
				this.mediaRecorder.stop();
			}
			this.toggleRecordingBtnDisabled = true;
		},

		trackVolume() {
			if (!this.recordingProccessStarted || this.recordingProccessPaused) {
				return;
			}

			const volume = this.mediaRecorder.volume;
			this.bus.$emit("volume", volume);

			window.requestAnimationFrame(() => this.trackVolume());
		},

		/**
		 * @param {Blob} blob
		 */
		addMemo(blob) {
			const memoAudio = new MemoAudio(blob, this.recordDuration / 1000);
			const newMemo = new Memo(memoAudio);

			this.$refs.memoDnaRenderQueue
				.push(newMemo, this.audioVisualizer)
				.then((data) => {
					if (newMemo.id) {
						updateImage(newMemo);
					} else {
						newMemo.setImageFrom(data.blob);
					}
				});

			// newMemo._audioVisualizer = this.audioVisualizer;
			this.setCurrentMemo(newMemo);
			this.$emit("recorded");
		},

		async toggleRecording() {
			if (!this.mediaRecorder) {
				await this.initializeMediaRecorder();
				await this.initializeAudioVisualizer();
			}

			if (this.recordingProccessStarted) {
				this.stopRecording();
				return;
			}

			try {
				this.mediaRecorder.start();
			} catch (e) {
				console.error(e);
			}
			this.recordingProccessStarted = true;
		},

		async initializeMediaRecorder() {
			this.mediaRecorder = await getNewMediaRecorder();

			this.mediaRecorder.on("start", () => {
				this.trackVolume();
				this.timer.start();
				this.audioVisualizer.start();
			});

			this.mediaRecorder.on("resume", () => {
				this.trackVolume();
				this.timer.resume();
				this.audioVisualizer.resume();
			});

			this.mediaRecorder.on("pause", () => {
				this.timer.pause();
				this.audioVisualizer.pause();
			});

			this.mediaRecorder.on("stop", (blob) => {
				this.audioVisualizer.stop();
				this.addMemo(blob);
			});
		},

		async initializeAudioVisualizer() {
			this.audioVisualizer = await getNewAudioVisualizer({
				src: this.mediaRecorder.getStream(),
			});
			const visualization = new DnaVisualization(this.audioVisualizer, {
				scale: 0.1,
			});
			this.audioVisualizer.addVisualization(visualization);
		},
	},

	async created() {
		this.timer.on("update", (time) => {
			this.recordDuration = time;
			this.$emit("recording", this.recordDuration);
		});
	},
};
</script>
