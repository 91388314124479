<template>
	<AudioPlayer
		v-if="src && src != ''"
		ref="AudioPlayer"
		:src="src"
		:type="type"
		v-on="$listeners"
	/>
</template>

<script>
// @ts-check
import Memo from "@/lib/Memo";
import AudioPlayer from "./AudioPlayer.vue";
import { AudioBufferPlayer } from "@/lib/AudioBufferPlayer";

export default {
	components: { AudioPlayer },

	props: {
		memo: {
			type: Memo,
			required: true,
		},
	},

	data() {
		return {
			/** @type {string} */
			src: "",

			/** @type {AudioBufferPlayer} */
			audioBufferPlayer: null,
		};
	},

	computed: {
		type() {
			return this.memo.audio.type;
		},
	},

	watch: {
		memo() {
			this._initializeSource(true);
		},
		"memo.isSelectedDenoised"() {
			this._initializeSource();
		},
	},

	methods: {
		play() {
			if (this.memo.isSelectedDenoised) {
				try {
					if (this.audioBufferPlayer) this.audioBufferPlayer.play();
				} catch (e) {
					console.error(e);
				}
			} else if (this.$refs.AudioPlayer) {
				this.$refs.AudioPlayer.play();
			}
		},

		pause() {
			if (this.memo.isSelectedDenoised) {
				try {
					if (this.audioBufferPlayer) this.audioBufferPlayer.pause();
				} catch (e) {
					console.error(e);
				}
			} else if (this.$refs.AudioPlayer) {
				this.$refs.AudioPlayer.pause();
			}
		},

		getCurrentTime() {
			if (this.memo.isSelectedDenoised) {
				try {
					if (this.audioBufferPlayer) return this.audioBufferPlayer.currentTime;
					else return 0.0;
				} catch (e) {
					console.error(e);
				}
			} else if (this.$refs.AudioPlayer) {
				return this.$refs.AudioPlayer.getCurrentTime();
			}
		},

		setCurrentTime(time) {
			if (this.memo.isSelectedDenoised) {
				try {
					if (this.audioBufferPlayer) this.audioBufferPlayer.currentTime = time;
				} catch (e) {
					console.error(e);
				}
			} else if (this.$refs.AudioPlayer) {
				this.$refs.AudioPlayer.setCurrentTime(time);
			}
		},

		async _initializeSource(force = false) {
			if (this.memo.isSelectedDenoised) {
				this.src = "";
				if (force || !this.audioBufferPlayer) {
					console.log("forced", force);
					const audioBuffer = await this.memo.audio.denoise();
					this.audioBufferPlayer = new AudioBufferPlayer(audioBuffer);
					this.audioBufferPlayer.on("ended", () => this.$emit("ended"));
				}
			} else if (force || !this.src) {
				// hacky way of recreating <audio> element to be able to play new audio
				this.src = "";
				this.$nextTick(() => (this.src = this.memo.audio.url));
			}
		},
	},

	mounted() {
		this._initializeSource(true);
	},

	beforeDestroy() {
		this.pause();
	},
};
</script>
