// @ts-check

const state = {
  playlists: {},
};

const getters = {
  /**
   * @param {*} state
   * @returns {Object}
   */
  playlists: (state) => state.playlists,
};

const mutations = {
  /**
   * @param {Object} state
   * @param {Array} playlists
   */
  setPlaylists(state, playlists) {
    state.playlists = {};
    playlists.forEach((p) => (state.playlists[p.id] = p));
  },

  /**
   * @param {Object} state
   * @param {string} playlistId
   */
  deletePlaylist(state, playlistId) {
    try {
      delete state.playlists[playlistId];
      state.playlists = { ...state.playlists };
    } catch (e) {
      console.error(e);
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
