<template>
	<div class="h-100 d-flex flex-column">
		<search-bar />

		<div class="container memo-list flex-grow-1 d-flex flex-column">
			<memos-list />

			<div
				class="flex-col px-4 pb-4 bg-white fixed-bottom d-flex footer justify-content-center"
			>
				<upload-memo-button v-on:upload="uploadFile" />

				<add-memo v-on:openModal="recordModalShow = true" />
			</div>
		</div>

		<b-modal
			centered
			hide-footer
			hide-backdrop
			size="lg"
			v-model="recordModalShow"
			@hide="handleModalClose"
			@close="handleModalClose"
		>
			<record-modal
				@recording="handleRecording"
				@recorded="handleRecorded"
			></record-modal>
		</b-modal>

		<MemoModal @discard="handleDiscardEvent"></MemoModal>

		<create-playlist-modal />
		<add-memo-to-playlist-modal
			v-if="memoAddToPlaylistView"
			:memo="this.currentMemo"
		/>

		<MemoDnaRenderQueue ref="memoDnaRenderQueue" />
	</div>
</template>

<style scoped>
.memo-list {
	padding-bottom: calc(89px + 1rem);
}
</style>

<script>
// @ts-check
import AddMemo from "../components/AddMemo.vue";
import UploadMemoButton from "../components/UploadMemoButton.vue";
import MemosList from "../components/MemosList.vue";
import RecordModal from "../components/RecordModal.vue";
import { mapGetters, mapMutations } from "vuex";
import MemoModal from "../components/MemoModal/Modal.vue";
import SearchBar from "../components/SearchBar.vue";
import CreatePlaylistModal from "../components/Playlist/CreateModal.vue";
import AddMemoToPlaylistModal from "../components/Playlist/AddMemoModal.vue";
import MemoDnaRenderQueue from "@/components/MemoDnaRenderQueue.vue";

export default {
	components: {
		AddMemo,
		UploadMemoButton,
		MemosList,
		RecordModal,
		MemoModal,
		SearchBar,
		CreatePlaylistModal,
		AddMemoToPlaylistModal,
		MemoDnaRenderQueue,
	},

	data() {
		return {
			recordModalShow: false,
			isRecorded: false,
			recordingDuration: 0,
		};
	},

	created() {
		this.setMemoDetailsViewOpened(false);
	},

	computed: {
		...mapGetters("memo", ["currentMemo"]),
		...mapGetters("view", ["memoAddToPlaylistView"]),
	},

	methods: {
		...mapMutations("view", ["setMemoDetailsViewOpened"]),
		...mapMutations("memo", ["setIsNewMemo", "setCurrentMemo", "setEditMode"]),

		handleRecorded() {
			this.isRecorded = true;
			this.recordModalShow = false;
			this.setIsNewMemo(true);
			this.setEditMode(true);
			this.setMemoDetailsViewOpened(true);
		},

		handleDiscardEvent() {
			if (this.currentMemo) {
				this.$refs.memoDnaRenderQueue.cancel(this.currentMemo.localId);
			}

			this.recordModalShow = false;
			this.setMemoDetailsViewOpened(false);
			this.setCurrentMemo(null);
			this.setIsNewMemo(false);
		},

		handleModalClose(event) {
			if (
				!this.isRecorded &&
				this.recordingDuration > 0 &&
				!window.confirm(
					"Recording won't be saved, are you sure you want to continue?"
				)
			) {
				event.preventDefault();
			}

			// FIX: Recording continues after closing modal during recording
		},

		handleRecording(recordingDuration) {
			this.recordingDuration = recordingDuration;
		},

		uploadFile() {
			this.isRecorded = true;
			this.recordModalShow = false;
			this.setIsNewMemo(true);
			this.setEditMode(true);
			this.setMemoDetailsViewOpened(true);
		},
	},

	watch: {
		memoAddToPlaylistView(newValue) {
			this.$nextTick(() => {
				if (newValue) {
					this.$root.$emit("bv::show::modal", "add-memo-to-playlist-modal");
				} else {
					this.$root.$emit("bv::hide::modal", "add-memo-to-playlist-modal");
				}
			});
		},
	},
};
</script>
