// @ts-check

import Memo from "@/lib/Memo";
import {
  newMemoRequestDtoFromMemo,
  patchEditMemoInfoFromMemo,
  patchIsSelectedDenoisedFromMemo,
  updateMemoFromEditMemoInfo,
} from "@/dto/memo";
import { BASEPATH, isResponseOk } from "./utils";

/**
 * @returns {Promise<Memo[]>}
 */
export function listMemos() {
  return fetch(BASEPATH + "/v1/memo", {
    method: "GET",
    credentials: "include",
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {Memo} memo
 * @returns {Promise<Object>}
 */
export function newMemo(memo) {
  const form = new FormData();
  form.append("data", JSON.stringify(newMemoRequestDtoFromMemo(memo)));
  form.append("audio", memo.audio.blob);
  form.append("image", memo.imageBlob);

  return fetch(BASEPATH + "/v1/memo", {
    method: "POST",
    credentials: "include",
    headers: {
      // Requests with `Content-Type: multipart/form-data` (and some others) counts
      // as simple requests that do not need a preflight request which causes request
      // to fail because of CORS failure. Next line triggers preflight request.
      Authentication: "none",
    },
    body: form,
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {Memo} memo
 * @returns {Promise<Object>}
 */
export function editMemoInfo(memo) {
  if (!memo.id) return Promise.reject("memo.id is undefined");

  return fetch(BASEPATH + "/v1/memo/" + memo.id, {
    method: "PATCH",
    credentials: "include",
    body: JSON.stringify(patchEditMemoInfoFromMemo(memo)),
  })
    .then(isResponseOk)
    .then((res) => res.json())
    .then(updateMemoFromEditMemoInfo.bind(undefined, memo));
}

/**
 * @param {Memo} memo
 * @returns {Promise<Object>}
 */
export function deleteMemo(memo) {
  if (!memo.id) return Promise.reject("memo.id is undefined");

  return fetch(BASEPATH + "/v1/memo/" + memo.id, {
    method: "DELETE",
    credentials: "include",
  }).then(isResponseOk);
}

/**
 * @param {Memo} memo
 * @returns {Promise<Object>}
 */
export function updateAudio(memo) {
  if (!memo.id) return Promise.reject("memo.id is undefined");

  const form = new FormData();
  form.append("audio", memo.audio.blob);

  return fetch(BASEPATH + "/v1/memo/" + memo.id + "/audio", {
    method: "PATCH",
    credentials: "include",
    body: form,
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {Memo} memo
 * @returns {Promise<Object>}
 */
export function updateImage(memo) {
  if (!memo.id) return Promise.reject("memo.id is undefined");

  const form = new FormData();
  form.append("image", memo.imageBlob);

  return fetch(BASEPATH + "/v1/memo/" + memo.id + "/image", {
    method: "PATCH",
    credentials: "include",
    body: form,
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {Memo} memo
 * @returns {Promise<Response>}
 */
export function patchIsSelectedDenoised(memo) {
  if (!memo.id) return Promise.reject("memo.id is undefined");

  return fetch(BASEPATH + "/v1/memo/" + memo.id + "/is_selected_denoised", {
    method: "PATCH",
    credentials: "include",
    body: JSON.stringify(patchIsSelectedDenoisedFromMemo(memo)),
  }).then(isResponseOk);
}

/**
 * @returns {Promise<Response>}
 */
export function qouta() {
  return fetch(BASEPATH + "/v1/memo/quota", {
    method: "GET",
    credentials: "include",
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {string} artist
 * @param {string} title
 * @returns {Promise<string>}
 */
export function meta(artist, title) {
  return fetch(
    BASEPATH +
      "/v1/memo/meta?artist=" +
      encodeURIComponent(artist) +
      "&title=" +
      encodeURIComponent(title),
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then(isResponseOk)
    .then((res) => res.json());
}
