import { AbstractVisualization } from "./AbstractVisualization";
import { AudioVisualizer } from "./AudioVisualizer";

export class WaveformVisualization extends AbstractVisualization {
  /**
   * @param {AudioVisualizer} audioVisualizer
   * @param {import("./AudioVisualizer").VisualizationOptions} [options]
   * @returns
   */
  constructor(audioVisualizer, options = {}) {
    super(audioVisualizer, options);

    this.initContext();
    this.fillBackground();
  }

  update() {
    if (this.isStopped) return;

    this.audioVisualizer.analyser.getByteTimeDomainData(
      this.audioVisualizer.dataArray
    );

    let w = this.cnv.width;
    let h = this.cnv.height;

    this.ctx.fillStyle =
      this.options.background ||
      AbstractVisualization.DefaultOptions.background;
    this.ctx.fillRect(0, 0, w, h);

    this.ctx.lineWidth =
      this.options.lineWidth || AbstractVisualization.DefaultOptions.lineWidth;
    this.ctx.strokeStyle =
      this.options.strokeStyle ||
      AbstractVisualization.DefaultOptions.strokeStyle;
    this.ctx.beginPath();

    let sliceWidth = w / this.audioVisualizer.bufferLength;
    let x = 0;

    for (let i = 0; i < this.audioVisualizer.bufferLength; i++) {
      if (this.isStopped) return;

      let n = this.audioVisualizer.dataArray[i] / 128.0;
      let y = (n * h) / 2;

      if (i === 0) {
        this.ctx.moveTo(x, y);
      } else {
        this.ctx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    this.ctx.lineTo(w, h / 2);
    this.ctx.stroke();
  }
}
