import { AbstractSpectrumVisualization } from "./AbstractSpectrumVisualization";
import { AbstractVisualization } from "./AbstractVisualization";
import { AudioVisualizer } from "./AudioVisualizer";

/**
 * @property {import("./AudioVisualizer").VisualizationOptions & {direction: SpectrumVisualization.Direction}} options
 */
export class SpectrumVisualization extends AbstractSpectrumVisualization {
  /**
   * @param {AudioVisualizer} audioVisualizer
   * @param {import("./AudioVisualizer").VisualizationOptions & {direction: SpectrumVisualization.Direction}} [options]
   * @returns
   */
  constructor(audioVisualizer, options = {}) {
    super(audioVisualizer, options);

    this.initContext();
    this.fillBackground();

    switch (this.options.direction) {
      case SpectrumVisualization.Direction.HORIZONTAL:
        this.newLineCnv.width = 1;
        this.newLineCnv.height = this.audioVisualizer.bufferLength;
        this.newLineImageData = new ImageData(
          new Uint8ClampedArray(
            new ArrayBuffer(this.audioVisualizer.bufferLength * 4)
          ),
          1,
          this.audioVisualizer.bufferLength
        );
        break;

      case SpectrumVisualization.Direction.VERTICAL:
      default:
        this.newLineCnv.width = this.audioVisualizer.bufferLength;
        this.newLineCnv.height = 1;
        this.newLineImageData = new ImageData(
          new Uint8ClampedArray(
            new ArrayBuffer(this.audioVisualizer.bufferLength * 4)
          ),
          this.audioVisualizer.bufferLength,
          1
        );
        break;
    }
  }

  /**
   * Draws the spectrum (waterfall graph).
   * @param {{elapsed: number}} props
   */
  update(props = { elapsed: 0 }) {
    if (this.isStopped) return;

    let rowsPerSec =
      this.options.rowsPerSec ||
      AbstractVisualization.DefaultOptions.rowsPerSec;
    this.lag += rowsPerSec * (props.elapsed / 1000);
    if (this.lag <= 1) return;

    let rowsToRender = Math.floor(this.lag);
    this.lag -= rowsToRender;

    this.audioVisualizer.analyser.getByteFrequencyData(
      this.audioVisualizer.dataArray
    );

    for (
      let i = 0, di = 0;
      i < this.audioVisualizer.bufferLength * 4;
      i += 4, di++
    ) {
      if (this.isStopped) return;

      const mappedColor = this.colormap[this.audioVisualizer.dataArray[di]];
      this.newLineImageData.data[i] = mappedColor[0];
      this.newLineImageData.data[i + 1] = mappedColor[1];
      this.newLineImageData.data[i + 2] = mappedColor[2];
      this.newLineImageData.data[i + 3] = 255;
    }

    this.newLineCtx.putImageData(this.newLineImageData, 0, 0);

    let w = this.cnv.width;
    let h = this.cnv.height;
    const imageData = this.ctx.getImageData(0, 0, w, h);

    switch (this.options.direction) {
      case SpectrumVisualization.Direction.HORIZONTAL:
        this.ctx.putImageData(imageData, rowsToRender, 0);
        this.ctx.drawImage(this.newLineCnv, 0, 0, rowsToRender, h);
        break;

      case SpectrumVisualization.Direction.VERTICAL:
      default:
        this.ctx.putImageData(imageData, 0, rowsToRender);
        this.ctx.drawImage(this.newLineCnv, 0, 0, w, rowsToRender);
        break;
    }
  }
}

SpectrumVisualization.Direction = {
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
};
Object.freeze(SpectrumVisualization.Direction);
