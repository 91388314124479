<template>
	<div class="position-absolute spinner-container">
		<b-icon icon="music-note-beamed" class="spinner" font-scale="4"></b-icon>
	</div>
</template>

<script>
export default {
	name: "LoadingSpinner",
};
</script>

<style scoped>
.spinner-container {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: white;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
