// @ts-check

import Peaks from "peaks.js";
import Memo from "./Memo";
import defaultPeaksOptions from "@/lib/constants/defaultPeakOptions.js";

/**
 * @typedef {Vue | Element | Vue[] | Element[]} Container
 *
 * @param {Container|{zoomview: Container, overview?: Container}} container
 * @param {Container} mediaElement
 * @param {{audioBuffer?: AudioBuffer, memo?: Memo}} props
 * @returns {Promise<Peaks>}
 */
export function initPeaks(container, mediaElement, { audioBuffer, memo }) {
  if (!(audioBuffer || memo)) {
    return Promise.reject("audioBuffer or memo is required");
  }

  return (audioBuffer
    ? Promise.resolve(audioBuffer)
    : memo.audio.decode()
  ).then((audioBuffer) => {
    return new Promise((resolve, reject) => {
      let options = Object.assign({}, defaultPeaksOptions, {
        mediaElement,
        webAudio: {
          audioBuffer,
        },
      });

      if (typeof container === "object") {
        options = Object.assign({}, options, { containers: container });
      } else {
        options = Object.assign({}, options, { container });
      }

      Peaks.init(options, (err, peaks) => {
        if (err) {
          console.error("Failed to initialize Peaks instance: " + err.message);
          reject(err);
          return;
        }

        const zoomview = peaks.views.getView("zoomview");
        zoomview.setZoom({
          seconds: audioBuffer.duration <= 10 ? audioBuffer.duration : 10,
        });
        zoomview.enableMarkerEditing(true);

        resolve(peaks);
      });
    });
  });
}
