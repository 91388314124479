<template>
	<div class="ml-1 mb-n3">
		<div class="icons d-flex justify-content-start" v-if="memoModalRichView">
			<b-icon
				icon="arrow-left-circle-fill"
				variant="primary"
				class="icon"
				title="Disable advanced view"
				@click="toggleMemoModalRichView"
			></b-icon>

			<b-icon
				icon="trash-fill"
				variant="primary"
				class="icon"
				title="Delete"
				@click="onDelete"
			></b-icon>

			<b-icon
				icon="pencil-fill"
				variant="primary"
				class="icon"
				title="Edit"
				@click="handleEditButtonPressed"
			></b-icon>

			<b-dropdown
				size="sm"
				variant="link"
				class="download"
				title="Download"
				toggle-class="text-decoration-none"
				no-caret
			>
				<template #button-content>
					<b-icon icon="download" variant="primary" class="icon"></b-icon>
				</template>

				<b-dropdown-item
					v-for="format in formats"
					:key="format.extension"
					@click="downloadConvertedTo(format.extension, format.codec)"
					>{{ format.extension }}</b-dropdown-item
				>
			</b-dropdown>

			<b-icon
				class="icon"
				icon="scissors"
				variant="primary"
				title="Crop"
				@click="openCropView"
			></b-icon>

			<b-icon
				class="icon"
				icon="collection-play"
				variant="primary"
				title="Manage Playlists"
				@click="openMemoAddToPlaylistView"
			></b-icon>
		</div>

		<div class="icons d-flex justify-content-start" v-else>
			<b-icon
				icon="three-dots-vertical"
				variant="primary"
				class="icon"
				title="Enable advanced view"
				@click="toggleMemoModalRichView"
			></b-icon>
		</div>
	</div>
</template>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";
import { ffmpegResponseDoneToBlobs } from "@/lib/ffmpeg/utils";
import { ffmpegConvert } from "@/lib/ffmpeg/ffmpegConvert";
import { ffmpegGetSupportedFormats } from "@/lib/ffmpeg/ffmpegGetSupportedFormats";
import { blobToFile, createUrlFrom } from "@/lib/utils";
import { deleteMemo } from "@/api/memo";

export default {
	name: "Header",

	data() {
		return {
			formats: [],
		};
	},

	computed: {
		...mapGetters("memo", ["currentMemo"]),
		...mapGetters("view", ["memoModalRichView"]),
	},

	methods: {
		...mapMutations("memo", [
			"setEditMode",
			"deleteMemo",
			"setCurrentMemo",
			"setIsNewMemo",
		]),
		...mapMutations("view", [
			"setMemoDetailsViewOpened",
			"setMemoAddToPlaylistView",
			"setMemoDetailsWaveformView",
			"setMemoModalRichView",
		]),

		openCropView() {
			this.$router.push("/crop");
		},

		onDelete() {
			const memo = this.currentMemo;

			this.$swal.fire({
				icon: "warning",
				title: "Deleting",
			});

			if (memo.id) {
				deleteMemo(memo)
					.then(() => {
						this.setMemoDetailsViewOpened(false);
						this.setCurrentMemo(null);
						this.deleteMemo(memo);

						this.$swal.fire({
							icon: "success",
							title: "Deleted",
							timer: 2000,
							timerProgressBar: true,
						});
					})
					.catch((err) => {
						console.error(err);
						this.$swal.fire({
							icon: "error",
							title: "Failed to delete",
							timer: 4000,
							timerProgressBar: true,
						});
					});
			} else {
				this.setMemoDetailsViewOpened(false);
				this.setCurrentMemo(null);
				this.deleteMemo(memo);
				this.$swal.fire({
					icon: "success",
					title: "Deleted",
					timer: 2000,
					timerProgressBar: true,
				});
			}
		},

		/**
		 * @param {string} fileExtension
		 * @param {string} [codec]
		 */
		downloadConvertedTo(fileExtension, outCodec) {
			ffmpegConvert(
				blobToFile(this.currentMemo.audio.blob, this.currentMemo.title),
				fileExtension,
				{ outCodec }
			)
				.then(ffmpegResponseDoneToBlobs)
				.then((blobs) => blobs[0])
				.then((audioBlob) => {
					const a = document.createElement("a");
					a.href = createUrlFrom(audioBlob);
					a.download = this.currentMemo.title + "." + fileExtension;
					a.click();
				})
				.catch(console.error);
		},

		openMemoAddToPlaylistView() {
			this.setMemoAddToPlaylistView(true);
		},

		toggleMemoModalRichView() {
			this.setMemoModalRichView(!this.memoModalRichView);
		},

		handleEditButtonPressed() {
			this.setIsNewMemo(false);
			this.setEditMode(true);
		},
	},

	mounted() {
		ffmpegGetSupportedFormats().then((formats) => (this.formats = formats));
	},
};
</script>
