<template>
	<div
		v-if="memos && memos.length > 0 && !memoListSortByPlaylistFirst"
		class="d-flex flex-grow-1 align-items-start"
	>
		<div
			class="row w-100"
			:class="{
				'compact-view': compactView,
				'memos-list': true,
			}"
		>
			<div
				v-for="(playlist, idx) in memosGroupedByPlaylist"
				:key="'memosGroupedByPlaylist' + idx"
				:class="[compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2']"
				class="memo-card"
				@click="openPlaylist(playlist.playlist.id)"
			>
				<MemoGroupCard :playlist="playlist" />
			</div>

			<div
				v-for="(memo, idx) in groupByPlaylistView
					? memosWithoutPlaylist
					: memos"
				:key="'groupByPlaylistView' + idx"
				:class="[compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2']"
				class="memo-card"
				@click="openDetails(memo)"
			>
				<MemoCard :memo="memo" />
			</div>
		</div>
	</div>

	<div v-else-if="memos && memos.length > 0">
		<h2>{{ selectedPlaylist.name }}</h2>

		<div
			class="row w-100"
			:class="{
				'compact-view': compactView,
				'memos-list': true,
			}"
		>
			<div
				v-for="(memo, idx) in memosFromPlaylist"
				:key="'memosFromPlaylist' + idx"
				:class="[compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2']"
				class="memo-card"
				@click="openDetails(memo)"
			>
				<MemoCard :memo="memo" />
			</div>
		</div>

		<h2 class="mt-4">Others</h2>

		<div
			class="row w-100"
			:class="{
				'compact-view': compactView,
				'memos-list': true,
			}"
		>
			<div
				v-for="(playlist, idx) in memosGroupedByPlaylistExceptCurrent"
				:key="'memosGroupedByPlaylistExceptCurrent' + idx"
				:class="[compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2']"
				class="memo-card"
				@click="openPlaylist(playlist.playlist.id)"
			>
				<MemoGroupCard :playlist="playlist" />
			</div>

			<div
				v-for="(memo, idx) in groupByPlaylistView
					? memosWithoutPlaylist
					: otherMemos"
				:key="'groupByPlaylistView' + idx"
				:class="[compactView ? 'col-md-3 col-sm-6' : 'col-6', 'items p-2']"
				class="memo-card"
				@click="openDetails(memo)"
			>
				<MemoCard :memo="memo" />
			</div>
		</div>
	</div>

	<div class="center-items flex-column flex-grow-1" v-else>
		<img class="img-fluid bg-img" src="../assets/images/record.jpg" />

		<span class="mt-4 text-secondary font-20">
			Click on the button to start recording new voice memo
		</span>
	</div>
</template>

<style lang="sass" scoped>
.bg-img
  width: 400px

.memo-card
  cursor: pointer
</style>

<script>
// @ts-check
import MemoGroupCard from "./MemoGroupCard.vue";
import MemoCard from "./MemoCard.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "MemosList",

	components: {
		MemoGroupCard,
		MemoCard,
	},

	data() {
		return {
			memosGroupedByPlaylist: {},
		};
	},

	computed: {
		...mapGetters("view", ["compactView", "groupByPlaylistView"]),
		...mapGetters("memo", ["memos", "memoListSortByPlaylistFirst"]),
		...mapGetters("playlist", ["playlists"]),

		selectedPlaylist() {
			return this.playlists[this.memoListSortByPlaylistFirst];
		},

		memosFromPlaylist() {
			return [...this.memos].filter(
				(m) => m.playlists.indexOf(this.memoListSortByPlaylistFirst) !== -1
			);
		},

		otherMemos() {
			return [...this.memos].filter(
				(m) => m.playlists.indexOf(this.memoListSortByPlaylistFirst) === -1
			);
		},

		memosWithoutPlaylist() {
			return [...this.memos].filter((m) => m.playlists.length === 0);
		},

		memosGroupedByPlaylistExceptCurrent() {
			return [...Object.values(this.memosGroupedByPlaylist)].filter(
				(p) => p.playlist.id !== this.memoListSortByPlaylistFirst
			);
		},
	},

	watch: {
		memos(memos) {
			this.updateMemosGroupedByPlaylist();
		},

		playlists(playlists) {
			this.updateMemosGroupedByPlaylist();
		},

		groupByPlaylistView(groupByPlaylistView) {
			this.updateMemosGroupedByPlaylist();
		},
	},

	methods: {
		...mapMutations("memo", [
			"setCurrentMemo",
			"setEditMode",
			"setMemoListSortByPlaylistFirst",
		]),
		...mapMutations("view", ["setMemoDetailsViewOpened"]),

		openDetails(memo) {
			this.setEditMode(false);
			this.setCurrentMemo(memo);
			this.setMemoDetailsViewOpened(true);
		},

		updateMemosGroupedByPlaylist() {
			this.memosGroupedByPlaylist = {};

			if (!this.groupByPlaylistView) return;

			for (const memo of this.memos) {
				if (memo.playlists.length) {
					for (const playlistId of memo.playlists) {
						if (!this.memosGroupedByPlaylist[playlistId]) {
							this.memosGroupedByPlaylist[playlistId] = {
								playlist: this.playlists[playlistId],
								memos: [],
							};
						}

						this.memosGroupedByPlaylist[playlistId].memos.push(memo);
					}
				}
			}
		},

		openPlaylist(playlistId) {
			console.log(playlistId);
			this.setMemoListSortByPlaylistFirst(playlistId);
		},
	},
};
</script>
