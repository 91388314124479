// @ts-check

import { NoiseFilter } from "./NoiseFilter";
import { getNewAudioContext } from "./soundUtils";
import { createUrlFrom } from "./utils";

const audioContext = getNewAudioContext();

export class MemoAudio {
  /** @type {Blob} */
  _blob;
  /** @type {boolean} */
  _isDenoised;
  /** @type {number|undefined} number of seconds */
  _duration;
  /** @type {AudioBuffer|undefined} */
  _decodedData;
  /** @type {AudioBuffer|undefined} */
  _denoisedAudio;
  /** @type {string|undefined} */
  _url;

  /**
   * @param {Blob} blob
   * @param {number} [duration]
   * @param {boolean} [isDenoised=false]
   */
  constructor(blob, duration, isDenoised = false) {
    this._blob = blob;
    this._isDenoised = isDenoised;

    this._duration = duration;
    if (!duration) {
      this.decode().then(
        (audioBuffer) => (this._duration = audioBuffer.duration)
      );
    }
  }

  get blob() {
    return this._blob;
  }

  get type() {
    return this._blob.type;
  }

  get duration() {
    return this._duration;
  }

  /**
   * @returns {Promise<AudioBuffer>}
   */
  decode() {
    if (this._decodedData) {
      return Promise.resolve(this._decodedData);
    }

    return this._blob
      .arrayBuffer()
      .then((ab) => audioContext.decodeAudioData(ab))
      .then((audioBuffer) => {
        this._decodedData = audioBuffer;
        return audioBuffer;
      });
  }

  /**
   * @returns {AudioBuffer|null}
   */
  get denoisedAudio() {
    return this._denoisedAudio;
  }

  get isDenoised() {
    return !!this._denoisedAudio;
  }

  /**
   * @returns {Promise<AudioBuffer>}
   */
  denoise() {
    if (this._denoisedAudio) {
      return Promise.resolve(this._denoisedAudio);
    }

    return NoiseFilter.create()
      .then((noiseFilter) => noiseFilter.filter(this))
      .then((denoisedAudio) => {
        this._denoisedAudio = denoisedAudio;
        return denoisedAudio;
      });
  }

  get url() {
    if (!this._url) {
      this._url = createUrlFrom(this._blob);
    }

    return this._url;
  }

  destroy() {
    if (this._url) URL.revokeObjectURL(this._url);
  }
}
