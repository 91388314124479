// @ts-check
import * as EventEmitter from "eventemitter3";
import { getNewAudioContext } from "../soundUtils";
import { AudioVisualizerLOGGER } from "./debug";
import { getStreamSource } from "./utils";

// @see https://github.com/fjw/audiovisualizer/blob/master/audiovisualizer.js

/**
 * @typedef AudioVisualizerOptions
 * @property {string|MediaStream} [src]
 * @property {AnalyserNode} [analyser]
 *
 * @typedef VisualizationInterface
 * @property {VisualizationOptions} [options]
 * @property {CanvasRenderingContext2D} ctx
 * @property {HTMLCanvasElement} cnv
 * @property {(props: Object) => void} update
 * @property {() => void} stop
 *
 * @typedef VisualizationOptions
 * @property {HTMLCanvasElement} [canvas]
 * @property {string|HTMLElement} [container]
 * @property {string} [background]
 * @property {string[]} [colortheme]
 * @property {number} [rowsPerSec]
 * @property {number} [lineWidth]
 * @property {string} [strokeStyle]
 */

/**
 * @param {AudioVisualizerOptions} [options]
 * @returns {Promise<AudioVisualizer>}
 */
export function getNewAudioVisualizer(options = {}) {
  /** @type {AudioContext} */
  let audioCtx = getNewAudioContext();

  return getStreamSource(audioCtx, options.src).then(
    (source) => new AudioVisualizer(audioCtx, source, options)
  );
}

export class AudioVisualizer extends EventEmitter {
  /** @type {VisualizationInterface[]} */
  _visualizations = []; // list of visualizations

  /**@type {AudioContext} */
  _audioCtx = null;

  /** @type {AnalyserNode} */
  _analyser = null;

  /** @type {MediaStreamAudioSourceNode|MediaElementAudioSourceNode} */
  _source = null;

  /** @type {boolean} */
  _isStopped = false;

  /** @type {boolean} */
  _isEnded = false;

  /**
   * @param {AudioContext} audioCtx
   * @param {MediaStreamAudioSourceNode|MediaElementAudioSourceNode} source
   * @param {AudioVisualizerOptions} options
   */
  constructor(audioCtx, source, options) {
    super();

    AudioVisualizerLOGGER(
      "constructor:\naudioCtx: %O;\nsource: %O;\noptions: %O",
      audioCtx,
      source,
      options
    );

    this._audioCtx = audioCtx;
    this._analyser = audioCtx.createAnalyser();

    this._source = source;
    source.connect(this._analyser);

    if (options.analyser) {
      for (const [key, value] of Object.entries(options.analyser)) {
        this._analyser[key] = value;
      }
    }

    this.bufferLength = this._analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(this.bufferLength);

    this._registerSourceEventListeners();
  }

  /**
   * @returns {boolean}
   */
  get isEnded() {
    return this._isEnded;
  }

  /**
   * @param {undefined|string|MediaStream} newsource
   */
  setSource(newsource) {
    AudioVisualizerLOGGER("setSource: %o", newsource);

    this._source.disconnect(this._analyser);
    getStreamSource(this._audioCtx, newsource)
      .then((source) => {
        this._source = source;
        source.connect(this._analyser);

        this._registerSourceEventListeners();
      })
      .catch(console.error);
  }

  _registerSourceEventListeners() {
    if (this._source instanceof MediaElementAudioSourceNode) {
      this._source.mediaElement.addEventListener(
        "ended",
        this._onEnded.bind(this)
      );
    } else if (this._source instanceof MediaStreamAudioSourceNode) {
      this._source.mediaStream.addEventListener(
        "inactive",
        this._onEnded.bind(this)
      );
    }
  }

  start() {
    AudioVisualizerLOGGER("start");

    // timing
    let last = Date.now();

    const draw = () => {
      if (this._isStopped || this._isEnded) return;

      window.requestAnimationFrame(draw);

      // timing
      let current = Date.now();
      let elapsed = current - last;
      last = current;

      this._visualizations.forEach((visualization) =>
        visualization.update({ elapsed })
      );
    };

    draw();

    this.emit("started", this);
  }

  resume() {
    AudioVisualizerLOGGER("resume");
    this._isStopped = false;
    this.start();
    this.emit("resumed", this);
  }

  pause() {
    AudioVisualizerLOGGER("pause");
    this._isStopped = true;
    this.emit("paused", this);
  }

  stop() {
    AudioVisualizerLOGGER("stop");
    this._isStopped = true;
    this._visualizations.forEach((visualization) => visualization.stop());
    this.emit("stopped", this);
  }

  get analyser() {
    return this._analyser;
  }

  /**
   * @param {VisualizationInterface} visualization
   * @returns {AudioVisualizer}
   */
  addVisualization(visualization) {
    AudioVisualizerLOGGER("addVisualization: %o", visualization);
    this._visualizations.push(visualization);
    return this;
  }

  /**
   * @returns {VisualizationInterface[]}
   */
  getVisualizations() {
    return this._visualizations;
  }

  _onEnded() {
    AudioVisualizerLOGGER("_onEnded: source has ended");
    this._isEnded = true;
    this.emit("ended", this);
  }

  on(event, fn, context = null) {
    const subResult = super.on(event, fn, context);

    if (event === "ended" && this.isEnded) {
      this.emit("ended", this);
    }

    return subResult;
  }
}
