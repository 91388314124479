import * as EventEmitter from "eventemitter3";
import { createAudioMeter } from "./createAudioMeter";
import { getNewAudioContext } from "./soundUtils";
import { createBlobFrom } from "./utils";

/** @type {AudioContext} */
const audioContext = getNewAudioContext();

/**
 * @param {Object} [options]
 * @returns {Promise<MediaRecorderWrapper>}
 */
export function getNewMediaRecorder(options = {}) {
  audioContext.resume();
  return Promise.resolve()
    .then(() => {
      if (navigator.mediaDevices) {
        return navigator.mediaDevices.getUserMedia({ audio: true });
      } else {
        throw new Error(
          "Your browser does not support recording via microphone!"
        );
      }
    })
    .then((mediaStream) => {
      const mediaRecorder = new MediaRecorder(mediaStream, options);
      return new MediaRecorderWrapper(mediaRecorder);
    })
    .catch((err) => {
      alert(alert);
      throw err;
    });
}

export class MediaRecorderWrapper extends EventEmitter {
  /** @type {number} */
  _volume = 0.0;

  /** @type {Blob[]} */
  _blobs = [];

  /**
   * @param {MediaRecorder} mediaRecorder
   */
  constructor(mediaRecorder) {
    super();

    /** @type {MediaRecorder} */
    this._mediaRecorder = mediaRecorder;

    this._meter = createAudioMeter(audioContext);
    const stream = audioContext.createMediaStreamSource(mediaRecorder.stream);

    this._mediaRecorder.onstart = () => {
      this._meter.connectTo(stream);
      this.emit("start");
    };

    this._mediaRecorder.onresume = () => {
      this._meter.connectTo(stream);
      this.emit("resume");
    };

    this._mediaRecorder.onpause = () => {
      this._meter.disconnect();
      this.emit("pause");
    };

    this._mediaRecorder.onstop = () => {
      this._meter.shutdown();
      this.emit(
        "stop",
        createBlobFrom(this._blobs, { type: this._blobs[0].type })
      );
    };

    this._mediaRecorder.ondataavailable = (e) => {
      this._blobs.push(e.data);
    };
  }

  get volume() {
    return (this._meter || {}).volume || 0.0;
  }

  start() {
    this._mediaRecorder.start();
  }

  resume() {
    this._mediaRecorder.resume();
  }

  pause() {
    this._mediaRecorder.pause();
  }

  stop() {
    if (!this._mediaRecorder.stream) return;
    let tracks = this._mediaRecorder.stream.getTracks();
    tracks.forEach((track) => track.stop());
  }

  /**
   * @returns {MediaStream}
   */
  getStream() {
    return this._mediaRecorder.stream;
  }
}
