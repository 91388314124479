<template>
	<div
		class="container my-2 flex-column flex-sm-row d-flex"
		v-if="memos && memos.length > 0"
	>
		<b-form-input
			class="p-3 border-0 search-input"
			v-model="searchString"
			type="text"
			placeholder="Search"
		></b-form-input>

		<div class="mt-3 mb-3 ml-0 mt-sm-0 mb-sm-0 ml-sm-3 d-flex flex-row">
			<b-dropdown
				:text="memoListSortByPlaylistFirst ? selectedPlaylistName : 'Playlist'"
				class="playlist-dropdown w-100"
			>
				<b-dropdown-item
					v-for="(playlist, playlistId) in playlists"
					:key="playlistId"
					:active="memoListSortByPlaylistFirst == playlistId"
					@click="_setMemoListSortByPlaylistFirst(playlistId)"
				>
					{{ playlist.name }}
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item
					@click="_setMemoListSortByPlaylistFirst(undefined, '')"
				>
					Clear sort
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item active v-b-modal.playlist-create-modal>
					Create playlist
				</b-dropdown-item>
			</b-dropdown>

			<b-button
				@click="toggleGroupByPlaylist"
				class="ml-2"
				title="Toggle group by playlist"
				:variant="groupByPlaylistView ? 'primary' : ''"
				pill
			>
				<b-icon class="icon" icon="collection-play"></b-icon>
			</b-button>
		</div>

		<div class="pl-3 d-none d-sm-inline">
			<b-button-group class="mx-1">
				<b-button
					:class="{
						'text-white bg-primary': !compactView,
						'toggle-view-style-btn': true,
					}"
					@click="setCompactView(false)"
				>
					<b-icon icon="list" />
				</b-button>

				<b-button
					:class="{
						'text-white bg-primary': compactView,
						'toggle-view-style-btn': true,
					}"
					@click="setCompactView(true)"
				>
					<b-icon icon="grid" />
				</b-button>
			</b-button-group>
		</div>
	</div>
</template>

<style>
.playlist-dropdown button {
	border-radius: 50rem;
}
.playlist-dropdown ul {
	width: 100%;
}
</style>

<script>
// @ts-check
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "SearchBar",

	data() {
		return {
			searchString: "",
			selectedPlaylistName: "",
		};
	},

	computed: {
		...mapGetters("memo", ["memos", "memoListSortByPlaylistFirst"]),
		...mapGetters("view", ["compactView", "groupByPlaylistView"]),
		...mapGetters("playlist", ["playlists"]),
	},

	methods: {
		...mapMutations("view", ["setCompactView", "setGroupByPlaylistView"]),
		...mapMutations("memo", ["setMemoListSortByPlaylistFirst"]),

		_setMemoListSortByPlaylistFirst(playlistId) {
			this.setMemoListSortByPlaylistFirst(playlistId);
		},

		toggleGroupByPlaylist() {
			this.setGroupByPlaylistView(!this.groupByPlaylistView);
		},
	},

	watch: {
		memoListSortByPlaylistFirst(playlistId) {
			const playlist = this.playlists[playlistId];
			if (playlist) this.selectedPlaylistName = playlist.name;
		},
	},
};
</script>

<style lang="sass" scoped>
.search-input
  border-radius: 20px
  background: #f2f2f2

.toggle-view-style-btn
  font-size: 1.2rem
  display: flex
  justify-content: center
  align-items: center
  padding: 8px 12px
</style>
