export const Views = {
  CROP: 1,
  DETAILS: 2,
  ADD_TO_PLAYLIST: 3,
};
Object.freeze(Views);

function setPreviousView(state) {
  state.previousView = state.cropViewOpened
    ? Views.CROP
    : state.memoDetailsViewOpened
    ? Views.DETAILS
    : state.memoAddToPlaylistView
    ? Views.ADD_TO_PLAYLIST
    : null;
}

const state = {
  compactView: true,
  cropViewOpened: false,
  memoDetailsViewOpened: false,
  memoAddToPlaylistView: false,
  previousView: null,
  groupByPlaylistView: false,
  memoDetailsWaveformView: false,
  memoModalRichView: false,
};

const getters = {
  compactView: (state) => state.compactView,
  cropViewOpened: (state) => state.cropViewOpened,
  memoDetailsViewOpened: (state) => state.memoDetailsViewOpened,
  memoAddToPlaylistView: (state) => state.memoAddToPlaylistView,
  previousView: (state) => state.previousView,
  groupByPlaylistView: (state) => state.groupByPlaylistView,
  memoDetailsWaveformView: (state) => state.memoDetailsWaveformView,
  memoModalRichView: (state) => state.memoModalRichView,
};

const mutations = {
  setCompactView(state, compactView) {
    state.compactView = compactView;
  },
  setCropViewOpened(state, cropViewOpened) {
    setPreviousView(state);
    state.cropViewOpened = cropViewOpened;
  },
  setMemoDetailsViewOpened(state, memoDetailsViewOpened) {
    setPreviousView(state);
    state.memoDetailsViewOpened = memoDetailsViewOpened;
  },
  setMemoAddToPlaylistView(state, memoAddToPlaylistView) {
    setPreviousView(state);
    state.memoAddToPlaylistView = memoAddToPlaylistView;
  },
  setPreviousView(state, previousView) {
    state.previousView = previousView;
  },
  setGroupByPlaylistView(state, groupByPlaylistView) {
    state.groupByPlaylistView = groupByPlaylistView;
  },

  setMemoDetailsWaveformView(state, memoDetailsWaveformView) {
    state.memoDetailsWaveformView = memoDetailsWaveformView;
  },

  setMemoModalRichView(state, memoModalRichView) {
    state.memoModalRichView = memoModalRichView;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
