<template>
	<div class="d-flex flex-row overflow-auto py-2" ref="scroll">
		<div
			v-for="(entry, index) in history.entries"
			:key="index"
			class="d-flex mr-4"
		>
			<history-entry
				v-if="index != 0"
				:history="history"
				:initialEntry="entry"
				@historyEntrySelect="handleHistoryEntrySelect"
			></history-entry>
		</div>
	</div>
</template>

<script>
// @ts-check
import HistoryEntry from "./HistoryEntry.vue";
import { MemoCropHistory } from "@/lib/MemoCropHistory";

export default {
	name: "CropHistory",
	components: {
		HistoryEntry,
	},

	props: {
		initialHistory: {
			required: true,
			type: MemoCropHistory,
		},
		scrollTrigger: undefined,
	},

	data() {
		return {
			/**
			 * @type {MemoCropHistory}
			 */
			history: this.initialHistory,
		};
	},

	watch: {
		scrollTrigger: function (oldValue, newValue) {
			if (newValue != oldValue) this.scrollToTheEnd();
		},
	},

	methods: {
		handleHistoryEntrySelect(historyEntry) {
			this.history.setCurrentIndex(historyEntry.index);
			this.$emit("historyEntrySelect", historyEntry);
		},

		scrollToTheEnd() {
			const scroll = this.$refs.scroll;
			if (scroll && (scroll instanceof HTMLElement || scroll instanceof Node)) {
				scroll.scrollLeft = scroll.scrollWidth;
			}
		},
	},
};
</script>
