// @ts-check

import Memo from "@/lib/Memo";

/**
 * @param {Memo} memo
 * @returns {Object}
 */
export function newMemoRequestDtoFromMemo(memo) {
  return {
    title: memo.title,
    description: memo.description,
  };
}

/**
 * @param {Memo} memo
 * @returns {Object}
 */
export function patchEditMemoInfoFromMemo(memo) {
  return {
    title: memo.title,
    description: memo.description,
  };
}

/**
 * @param {Memo} memo
 * @param {Object} editMemoInfo
 * @returns {Object}
 */
export function updateMemoFromEditMemoInfo(memo, editMemoInfo) {
  memo.title = editMemoInfo.title;
  memo.description = editMemoInfo.description;
  return editMemoInfo;
}

/**
 * @param {Memo} memo
 * @returns {Object}
 */
export function patchIsSelectedDenoisedFromMemo(memo) {
  return {
    is_selected_denoised: memo.isSelectedDenoised,
  };
}
