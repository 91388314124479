import Vue from "vue";
import Vuex from "vuex";
import memo from "./modules/memo";
import view from "./modules/view";
import user from "./modules/user";
import playlist from "./modules/playlist";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    memo,
    view,
    user,
    playlist,
  },
});
