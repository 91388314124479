<template>
	<button
		@click="handleHistoryEntryClick"
		class="position-relative shadow-sm rounded card p-0"
		:class="{ current: isCurrent }"
	>
		<MemoWaveform
			:cropHistoryEntry="entry.audio"
			:key="waveformRerenderTrigger"
			@rendered="onWaveformRendered"
		/>

		<span class="position-absolute title">{{ entry.index }}.</span>

		<loading-spinner v-if="isLoading"></loading-spinner>
	</button>
</template>

<script>
// @ts-check
import MemoWaveform from "../../MemoWaveform/MemoWaveform.vue";
import LoadingSpinner from "../../LoadingSpinner.vue";
import { CropHistoryEntry, MemoCropHistory } from "@/lib/MemoCropHistory";

export default {
	components: {
		MemoWaveform,
		LoadingSpinner,
	},

	props: {
		history: MemoCropHistory,
		initialEntry: {
			required: true,
			type: CropHistoryEntry,
		},
	},

	data() {
		return {
			/** @type {CropHistoryEntry} */
			entry: this.initialEntry,
			/** @type {number} */
			waveformRerenderTrigger: 0,
			/** @type {boolean} */
			isLoading: true,
		};
	},

	computed: {
		/** @returns {boolean} */
		isCurrent() {
			// TODO: FIX: isCurrent is not updated therefore current card isn't highlited
			return this.history.currentIndex === this.entry.index;
		},
	},

	watch: {
		/** @param {CropHistoryEntry} newValue */
		initialEntry: function (newValue) {
			this.isLoading = true;
			this.entry = newValue;
			this.waveformRerenderTrigger += 1;
		},
	},

	methods: {
		handleHistoryEntryClick() {
			this.$emit("historyEntrySelect", this.entry);
		},
		onWaveformRendered() {
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.card {
	width: 300px;
	height: 150px;
	border: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.card:active {
	box-shadow: none !important;
}
.card.current {
	background-color: rgba(57 157 250, 0.2);
}

.title {
	top: 1rem;
	left: 1rem;
}
</style>
