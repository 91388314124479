<template>
	<b-button
		variant="primary"
		size="lg"
		pill
		v-on:click="openModal"
		class="pl-5 py-2 display-6 position-relative flex-grow-1"
	>
		<b-icon class="plus-circle" icon="plus-circle"></b-icon>

		<span>
			Create
			<span class="d-none d-sm-inline">new project</span>
		</span>
	</b-button>
</template>

<style lang="sass" scoped>
button
  height: 65px
</style>

<script>
// @ts-check
export default {
	name: "AddMemo",
	methods: {
		openModal() {
			this.$emit("openModal");
		},
	},
};
</script>
