// @ts-check

import Memo from "@/lib/Memo";

const state = {
  currentMemo: {},
  memos: [],
  editMode: false,
  isNewMemo: false,
  memoListSortByPlaylistFirst: undefined,
};

const getters = {
  /**
   * @param {*} state
   * @returns {Memo[]}
   */
  memos: (state) => state.memos,

  /**
   * @param {*} state
   * @returns {Memo}
   */
  currentMemo: (state) => state.currentMemo,

  /**
   * @param {*} state
   * @returns {boolean}
   */
  editMode: (state) => state.editMode,

  /**
   * @param {*} state
   * @returns {boolean}
   */
  isNewMemo: (state) => state.isNewMemo,

  /**
   * @param {*} state
   * @returns {string|undefined}
   */
  memoListSortByPlaylistFirst: (state) => state.memoListSortByPlaylistFirst,
};

const mutations = {
  setMemos(state, memos) {
    state.memos = memos;
  },

  setCurrentMemo(state, memo) {
    state.currentMemo = memo;
  },

  setEditMode(state, editMode) {
    state.editMode = editMode;
  },

  setIsNewMemo(state, isNewMemo) {
    state.isNewMemo = isNewMemo;
  },

  deleteMemo(state, memo) {
    for (let i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === memo.id) {
        /** @type {Memo[]} */
        const removedMemos = state.memos.splice(i, 1);
        for (const removedMemo of removedMemos) {
          removedMemo.destroy();
        }
        return;
      }
    }
  },

  updateMemo(state, memo) {
    for (let i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === memo.id) {
        state.memos.splice(i, 1, memo);
        return;
      }
    }
  },

  addMemo(state, memo) {
    state.memos = [...state.memos, memo];
  },

  upsertMemo(state, memo) {
    for (let i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === memo.id) {
        state.memos.splice(i, 1, memo);
        return;
      }
    }
    state.memos = [...state.memos, memo];
    state.isNewMemo = false;
  },

  addToPlaylist(state, { memoId, playlistId }) {
    for (let i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === memoId) {
        state.memos[i]._playlists = [...state.memos[i]._playlists, playlistId];
        return;
      }
    }
  },

  removeFromPlaylist(state, { memoId, playlistId }) {
    for (let i = 0; i < state.memos.length; i++) {
      if (state.memos[i].id === memoId) {
        const idx = state.memos[i]._playlists.indexOf(playlistId);
        if (idx !== -1) {
          state.memos[i]._playlists.splice(idx, 1);
          return;
        }
      }
    }
  },

  setMemoListSortByPlaylistFirst(state, playlistId) {
    state.memoListSortByPlaylistFirst = playlistId;
  },

  tryUpdateMemoImageByLocalId(state, { localId, blob }) {
    for (const memo of state.memos) {
      if (memo.localId === localId) {
        memo.setImageFrom(blob);
        return;
      }
    }

    console.warn(
      `store/memo:tryUpdateMemoImageByLocalId: Memo with id "${localId}" wasn't found.`
    );
  },
};

const actions = {
  updateCurrentMemo(state, memo) {
    state.commit("setCurrentMemo", memo);
    state.commit("updateMemo", memo);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
