import { render, staticRenderFns } from "./AddMemoModal.vue?vue&type=template&id=06b961c1&scoped=true&"
import script from "./AddMemoModal.vue?vue&type=script&lang=js&"
export * from "./AddMemoModal.vue?vue&type=script&lang=js&"
import style0 from "./AddMemoModal.vue?vue&type=style&index=0&id=06b961c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b961c1",
  null
  
)

export default component.exports