<template>
	<b-card v-if="playlist.playlist" class="row memo-group">
		<div class="row position-relative mb-2" style="width: 100px; height: 100px">
			<img
				src="../assets/images/volume.svg"
				width="100px"
				height="100px"
				class="volume-icon"
			/>

			<img
				v-for="(memo, index) in playlist.memos.slice(0, 5)"
				:key="memo.id"
				:src="memo.image"
				alt="memo-dna"
				:style="
					'width:100px;height:100px;transform:translateX(-50%);left:' +
					calcOffset(index)
				"
				width="100px"
				height="100px"
				class="memo-dna position-absolute top-0 mb-2 rounded-full"
			/>
		</div>

		<div class="row d-flex flex-row">
			<b-icon class="icon mr-2" icon="collection-play"></b-icon>

			<span>{{ playlist.playlist.name }}</span>

			<div
				class="delete-icon text-danger"
				v-on:click.stop="deleteHandler(playlist)"
				title="Delete"
			></div>
		</div>
	</b-card>
</template>

<style scoped>
.rounded-full {
	border-radius: 9999px;
}
</style>

<script>
// @ts-check
import * as api from "@/api";
import { mapMutations } from "vuex";

export default {
	name: "MemoGroupCard",

	props: {
		playlist: {
			type: Object,
			required: true,
		},
	},

	methods: {
		...mapMutations("playlist", ["deletePlaylist"]),
		...mapMutations("memo", ["removeFromPlaylist"]),

		calcOffset(i) {
			const offset = 1;

			let result = "calc(50%";

			if (i > 0) {
				result += " + " + offset * i + "rem";
			}

			if (this.playlist.memos.length > 1) {
				result += " - " + offset * (this.playlist.memos.length - 1 - i) + "rem";
			}

			return result + ")";
		},

		async deleteHandler(playlist) {
			try {
				const playlistId = playlist.playlist.id;
				await api.playlist.del(playlistId);
				this.deletePlaylist(playlistId);
				for (const memo in playlist.memos) {
					this.removeFromPlaylist({ memoId: memo.id, playlistId });
				}
				this.$swal.fire({
					icon: "success",
					title: "Deleted",
					timer: 2000,
					timerProgressBar: true,
				});
			} catch (e) {
				console.error(e);
				this.$swal.fire({
					icon: "error",
					title: "Failed to delete",
					timer: 4000,
					timerProgressBar: true,
				});
			}
		},
	},
};
</script>
