/**
 * Transforms a CSS color like "rgb(1, 2, 3)" or "#f00" into color value array ala [1, 2, 3]
 *
 * @param {string} color  CSS color
 * @returns {number[]}  rgb array [r,g,b]
 */
export function cssColorToArray(color) {
  if (color.match(/rgb/)) {
    // rgb( 1, 2, 3)
    let colors = color.match(/\d+/g);

    if (colors.length !== 3) {
      console.error("only hex and rgb(r,g,b) colors are allowed");
    }

    return colors.map((color) => +color); //c.map(i => parseInt(i)); todo
  } else if (color.match(/#/)) {
    // #fff or #ffffff
    color = color.trim().replace("#", "");
    if (color.length === 3) {
      color =
        color.charAt(0) +
        color.charAt(0) +
        color.charAt(1) +
        color.charAt(1) +
        color.charAt(2) +
        color.charAt(2);
    }

    return [
      parseInt(color.substring(0, 2), 16),
      parseInt(color.substring(2, 4), 16),
      parseInt(color.substring(4, 6), 16),
    ];
  }
}

/**
 * Gets color values between two colors. [r,g,b]
 *
 * @param {number[]} color1  first color
 * @param {number[]} color2  second color (excluded in the result)
 * @param {number} amount  amount of color values needed
 * @returns {number[][]}  array of colors
 */
export function colorsInBetween(color1, color2, amount) {
  const distancesRGB = [
    Math.abs(color2[0] - color1[0]),
    Math.abs(color2[1] - color1[1]),
    Math.abs(color2[2] - color1[2]),
  ];

  const stepsRGB = [
    distancesRGB[0] / amount,
    distancesRGB[1] / amount,
    distancesRGB[2] / amount,
  ];

  const directionRGB = [
    Math.sign(color2[0] - color1[0]),
    Math.sign(color2[1] - color1[1]),
    Math.sign(color2[2] - color1[2]),
  ];

  let colorsInBetween = [];

  for (let i = 0; i < amount; i++) {
    colorsInBetween.push([
      Math.round(color1[0] + i * stepsRGB[0] * directionRGB[0]),
      Math.round(color1[1] + i * stepsRGB[1] * directionRGB[1]),
      Math.round(color1[2] + i * stepsRGB[2] * directionRGB[2]),
    ]);
  }

  return colorsInBetween;
}

/**
 * Prepares the colormap for rendering. So we have an array 0-255 for all possible values.
 *
 * @param {string[]} colortheme  array of css colors
 * @returns {number[][]}  array of [r,g,b] colors with 256 elements
 */
export function prepareColorMap(colortheme) {
  let ct = colortheme.map(cssColorToArray);
  let distancecount = colortheme.length - 1;

  // we need an array with 256 entries
  const colorAmountPerStep = Math.floor(255 / distancecount);
  let oneMoreForHowMany = 255 - colorAmountPerStep * distancecount;

  let colormap = [];
  for (let i = 0; i < distancecount; i++) {
    let amount = colorAmountPerStep;
    if (oneMoreForHowMany > 0) {
      amount++;
      oneMoreForHowMany--;
    }
    colormap = colormap.concat(colorsInBetween(ct[i], ct[i + 1], amount));
  }

  colormap.push(colortheme[colortheme.length - 1]); // add last color

  return colormap;
}

/**
 * Creates a canvas and fits it into the container. If the container resizes, the fittet canvas is resized, too.
 * (..without loosing image information)
 *
 * @param {string|HTMLElement} container  the css query for the container
 * @returns {HTMLCanvasElement}  the context and the canvas
 */
export function createCanvasInContainer(container) {
  /** @type {HTMLElement} */
  let containerEl;
  if (typeof container === "string") {
    containerEl = document.querySelector(container);
  } else {
    containerEl = container;
  }

  containerEl.style.position = "relative";
  containerEl.style.overflow = "hidden";

  const cnv = document.createElement("canvas");
  cnv.style.position = "absolute";
  cnv.style.top = "0";
  cnv.style.left = "0";
  cnv.style.width = "100%";
  cnv.style.height = "100%";

  cnv.width = containerEl.clientWidth;
  cnv.height = containerEl.clientHeight;

  containerEl.append(cnv);

  return cnv;
}

/**
 * @param {HTMLCanvasElement} cnv
 * @param {CanvasRenderingContext2D|undefined} ctx
 */
export function registerResizeObserver(cnv, ctx) {
  const containerEl = cnv.parentElement;

  if (containerEl) {
    // auf resize reagieren
    let resize_to;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        clearTimeout(resize_to);
        resize_to = setTimeout(() => {
          const img = new Image();
          img.onload = () => {
            const w = containerEl.clientWidth;
            const h = containerEl.clientHeight;
            cnv.width = w;
            cnv.height = h;
            if (ctx) ctx.drawImage(img, 0, 0, w, h);
          };
          img.src = cnv.toDataURL();
        }, 200);
      }
    });
    resizeObserver.observe(containerEl);
  }
}

/**
 * Gets the an audio source for analysing.
 *
 * @param {AudioContext} actx  the audio context
 * @param {boolean|string|MediaStream|Audio|undefined} src  the url of the source or true for microphone
 * @returns {Promise<MediaStreamAudioSourceNode|MediaElementAudioSourceNode|null>}
 */
export function getStreamSource(actx, src) {
  if (src instanceof MediaStream) {
    return Promise.resolve(actx.createMediaStreamSource(src));
  } else if (typeof src === "string" || src instanceof Audio) {
    let audio = null;
    if (typeof src === "string") {
      audio = new Audio(src);
      audio.crossOrigin = "anonymous";
    } else {
      audio = src;
    }

    return audio.play().then((a) => actx.createMediaElementSource(audio)); //todo: geht das ohne play?
  } else if (src === true) {
    // microphone
    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => actx.createMediaStreamSource(stream));
  }

  return Promise.resolve(null);
}
