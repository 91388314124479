<template>
	<!-- vue requires template to have some element -->
	<i class="sr-only"></i>
</template>

<script>
// @ts-check
import Memo from "@/lib/Memo";
import { AudioVisualizer } from "@/lib/AudioVisualizer";
import { memoDnaRenderQueue } from "@/lib/MemoDnaRenderQueue";
import { createUnwrappedPromise } from "@/lib/utils";
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations("memo", ["tryUpdateMemoImageByLocalId"]),

		/**
		 * @param {Memo} memo
		 * @param {AudioVisualizer} audioVisualizer
		 * @returns {Promise<any>}
		 */
		push(memo, audioVisualizer) {
			return memoDnaRenderQueue
				.push(memo, audioVisualizer, createUnwrappedPromise())
				.then((data) => {
					this.tryUpdateMemoImageByLocalId({
						localId: data.localId,
						blob: data.blob,
					});
					return data;
				});
		},

		/**
		 * @param {string} memoLocalId
		 */
		cancel(memoLocalId) {
			if (memoLocalId === memoDnaRenderQueue.getCurrentTaskId()) {
				// Might interfere with other notifications
				this.$swal.close();
			}

			memoDnaRenderQueue.cancel(memoLocalId);
		},
	},

	created() {
		memoDnaRenderQueue.on("render:started", () =>
			this.$swal.fire({
				icon: "warning",
				title: "Creating cover...",
			})
		);

		memoDnaRenderQueue.on("render:ended", () =>
			this.$swal.fire({
				icon: "success",
				title: "Cover created!",
				timer: 2000,
				timerProgressBar: true,
			})
		);

		memoDnaRenderQueue.on("render:failed", () =>
			this.$swal.fire({
				icon: "error",
				title: "Failed to create cover",
				timer: 4000,
				timerProgressBar: true,
			})
		);
	},
};
</script>
