<template>
	<div class="container h-100">
		<peaks-containers></peaks-containers>
	</div>
</template>

<script>
// @ts-check
import PeaksContainers from "@/components/crop/PeaksContainers";

export default {
	name: "Crop",
	components: { PeaksContainers },
};
</script>
