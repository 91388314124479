<template>
	<nav>
		<div class="bg-primary text-white py-4 text-lg">
			<div class="container d-flex flex-row">
				<div class="flex-grow-1">
					<router-link to="/">
						<h4 class="text-light">Voice Memos</h4>
					</router-link>
				</div>

				<div class="position-relative">
					<CircularProgressBar
						class="position-absolute pr"
						title="Free space left"
						:text="progress.text"
						:progress="progress.number"
						color="white"
						textColor="white"
					/>
				</div>
			</div>
		</div>
	</nav>
</template>

<style scoped>
h4 {
	text-decoration: none !important;
}

.pr {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>

<script>
// @ts-check
import CircularProgressBar from "./CircularProgressBar.vue";
import * as api from "@/api";

export default {
	name: "Navbar",

	data() {
		return {
			progress: {
				text: "0%",
				number: 0,
			},
		};
	},

	components: {
		CircularProgressBar,
	},

	watch: {
		"progress.number": function (number) {
			this.progress.text =
				(number < 0.9 ? Math.round(number * 100) : Math.floor(number * 100)) +
				"%";
		},
	},

	mounted() {
		api.memo.qouta().then((data) => {
			this.progress.number = 1 - data.session / data.session_max;
		});
	},
};
</script>
