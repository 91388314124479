export default {
  // async logging function
  logger: console.error.bind(console),

  // if true, emit cue events on the Peaks instance (see Cue Events)
  emitCueEvents: false,

  // default height of the waveform canvases in pixels
  height: 200,

  // Array of zoom levels in samples per pixel (big >> small)
  // zoomLevels: [512, 1024, 2048, 4096],
  zoomLevels: [1, 4096],

  // Bind keyboard controls
  keyboard: true,

  // Keyboard nudge increment in seconds (left arrow/right arrow)
  nudgeIncrement: 0.01,

  // Color for segment start marker handles
  segmentStartMarkerColor: "#000000",

  // Color for segment end marker handles
  segmentEndMarkerColor: "#000000",

  // Color for the zoomable waveform. You can also use a 2 stop gradient here. See setWaveformColor().
  zoomWaveformColor: "rgba(0, 0, 0, 0.2)",

  // Color for the overview waveform. You can also use a 2 stop gradient here. See setWaveformColor().
  overviewWaveformColor: "rgba(0, 0, 0, 0.5)",
  // Color for the overview waveform rectangle
  // that shows what the zoomable view shows
  overviewHighlightColor: "transparent",

  // The default number of pixels from the top and bottom of the canvas
  // that the overviewHighlight takes up
  overviewHighlightOffset: 11,

  // Color for segments on the waveform
  segmentColor: "#399dfa",

  // Color of the play head
  playheadColor: "rgba(0, 0, 0, 0.7)",

  // Color of the play head text
  playheadTextColor: "#aaa",

  // Precision of time label of play head and point/segment markers
  timeLabelPrecision: 2,

  // Show current time next to the play head
  // (zoom view only)
  showPlayheadTime: false,

  // the color of a point marker
  pointMarkerColor: "#FF0000",

  // Color of the axis gridlines
  axisGridlineColor: "#ccc",

  // Color of the axis labels
  axisLabelColor: "#aaa",

  // Random color per segment (overrides segmentColor)
  randomizeSegmentColor: false,

  // Font family for axis labels, playhead, and point and segment markers
  fontFamily: "sans-serif",

  // Font size for axis labels, playhead, and point and segment markers
  fontSize: 11,

  // Font style for axis labels, playhead, and point and segment markers
  // (either 'normal', 'bold', or 'italic')
  fontStyle: "normal",
};
