<template>
	<b-modal
		id="playlist-create-modal"
		size="sm"
		title="Create playlist"
		hide-footer
	>
		<b-form @submit.stop.prevent="submitHandler">
			<div class="form-group">
				<b-form-input
					trim
					placeholder="Name"
					required
					v-model="name"
				></b-form-input>
			</div>

			<input
				class="form-control btn btn-primary"
				type="submit"
				value="Create"
			/>
		</b-form>
	</b-modal>
</template>

<script>
// @ts-check
import * as api from "@/api";
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "CreatePlaylistModal",

	data() {
		return {
			name: "",
		};
	},

	computed: {
		...mapGetters("playlist", ["playlists"]),
	},

	methods: {
		async submitHandler() {
			const res = await api.playlist.create({ name: this.name });
			this.$set(this.playlists, res.id, res);

			// Hide the modal manually
			this.$nextTick(() => {
				this.$bvModal.hide("playlist-create-modal");
			});

			this.name = "";
		},
	},
};
</script>
