import { BASEPATH, isResponseOk } from "./utils";

/**
 * @returns {Promise<Object[]>}
 */
export function list() {
  return fetch(BASEPATH + "/v1/playlist", {
    method: "GET",
    credentials: "include",
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {Object} playlist
 * @returns {Promise<Object>}
 */
export function create(playlist) {
  return fetch(BASEPATH + "/v1/playlist", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(playlist),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(isResponseOk)
    .then((res) => res.json());
}

/**
 * @param {string} playlistId
 * @returns {Promise<Response>}
 */
export function del(playlistId) {
  return fetch(BASEPATH + "/v1/playlist/" + playlistId, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(isResponseOk);
}

/**
 * @param {string} playlistId
 * @param {string} memoId
 * @returns {Promise<Response>}
 */
export function addMemo(playlistId, memoId) {
  return fetch(BASEPATH + "/v1/playlist/" + playlistId + "/add/" + memoId, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(isResponseOk);
}

/**
 * @param {string} playlistId
 * @param {string} memoId
 * @returns {Promise<Response>}
 */
export function removeMemo(playlistId, memoId) {
  return fetch(BASEPATH + "/v1/playlist/" + playlistId + "/remove/" + memoId, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(isResponseOk);
}
