// @ts-check

import { createUnwrappedPromise } from "../utils";

/**
 * @template T
 * @typedef {Object} FfmpegResponse
 * @property {string} type
 * @property {T} [data]
 */

/**
 * @typedef {Object} MemFsEntry
 * @property {string} name
 * @property {T} data
 * @template {ArrayLike|ArrayBufferLike} T
 */

/**
 * @typedef {Object} FfmpegResponseDone
 * @property {MemFsEntry<T>[]} MEMFS
 * @property {string[]} stdout
 * @property {string[]} stderr
 * @template {ArrayLike|ArrayBufferLike} T
 */

/** @type {Worker|undefined} */
let worker;
/** @type {boolean} */
let isWorkerReady = false;

/**
 * @param {(worker: Worker) => void} runCb
 * @returns {Promise<FfmpegResponseDone<ArrayLike>>}
 */
export function ffmpeg(runCb) {
  let thisWorker;
  if (worker) {
    thisWorker = worker;
  } else {
    thisWorker = worker = new Worker("ffmpeg-worker.js");
  }

  let stdout = [];
  let stderr = [];

  const { promise, resolve, reject } = createUnwrappedPromise();

  const workerMessageHandler = processWorkerEvent.bind(
    undefined,
    resolve,
    reject
  );
  thisWorker.addEventListener("message", workerMessageHandler, true);
  function removeEventListener() {
    thisWorker.removeEventListener("message", workerMessageHandler, true);
  }

  if (isWorkerReady) runCb(thisWorker);

  function processWorkerEvent(resolve, reject, event) {
    /** @type {FfmpegResponse<MemFsEntry<ArrayLike>[]|number>} */
    const message = event.data;
    // console.log(message);
    const messageData = message.data;

    switch (message.type) {
      case "ready":
        if (!isWorkerReady) {
          isWorkerReady = true;
          runCb(thisWorker);
        }
        break;

      case "stdout":
        stdout.push(messageData);
        break;

      case "stderr":
        stderr.push(messageData);
        break;

      case "done":
        removeEventListener();
        resolve(Object.assign({}, { stdout, stderr }, messageData));
        break;

      case "exit":
        // If exit returns non-zero result that means that some error occured
        if (messageData == 0) break;

      // @no-fallthrough
      case "abort":
      case "error":
        removeEventListener();
        reject(Object.assign({}, { stdout, stderr }, messageData));
        break;
    }
  }

  return promise;
}
