import { AbstractVisualization } from "./AbstractVisualization";
import { AudioVisualizer } from "./AudioVisualizer";
import { SpectrumVisualizationLOGGER } from "./debug";
import { prepareColorMap } from "./utils";

/**
 * @property {import("./AudioVisualizer").VisualizationOptions & {direction: SpectrumVisualization.Direction}} options
 */
export class AbstractSpectrumVisualization extends AbstractVisualization {
  /** @property {number} */
  lag;
  /** @property {number[][]} */
  colormap;
  /** @property {HTMLCanvasElement} */
  newLineCnv;
  /** @property {CanvasRenderingContext2D} */
  newLineCtx;

  /**
   * @param {AudioVisualizer} audioVisualizer
   * @param {import("./AudioVisualizer").VisualizationOptions & {direction: SpectrumVisualization.Direction}} [options]
   * @returns
   */
  constructor(audioVisualizer, options = {}) {
    super(audioVisualizer, options);

    this.lag = 0;
    this.colormap = prepareColorMap(
      options.colortheme || AbstractVisualization.DefaultOptions.colortheme
    );

    this.newLineCnv = document.createElement("canvas");
    this.newLineCtx = this.newLineCnv.getContext("2d");
  }
}
