// @ts-check

import { writable, get } from "svelte/store";
import EventEmitter from "eventemitter3";
import { AudioVisualizer, DnaVisualization } from "./AudioVisualizer";
import Memo from "./Memo";
import { defaultLogger } from "./debug";

const log = defaultLogger.extend("MemoDnaRenderQueue");

const INITIAL_STATE = {
  working: false,
  queue: [],
  tasksMap: {},
};

const store = writable(INITIAL_STATE);

class MemoDnaRenderQueue extends EventEmitter {
  /**
   * @returns {string|undefined}
   */
  getCurrentTaskId() {
    return this._getCurrentTaskId(get(store));
  }

  /**
   * @param {Object} data
   * @returns {string|undefined}
   */
  _getCurrentTaskId(data) {
    log("_getCurrentTaskId: data.working: %O", data.working);
    if (!data.working) return;

    log("_getCurrentTaskId: return: %O", data.queue[0]);
    return data.queue[0];
  }

  /**
   * @param {Memo} memo
   * @param {AudioVisualizer} audioVisualizer
   * @param {Object} unwrappedPromise
   * @returns {Promise<any>}
   */
  push(memo, audioVisualizer, unwrappedPromise) {
    log(
      "push: memo: %O, audioVisualizer: %O, unwrappedPromise: %O",
      memo,
      audioVisualizer,
      unwrappedPromise
    );

    const localId = memo.localId;
    const visualization = audioVisualizer
      .getVisualizations()
      .filter((v) => v instanceof DnaVisualization)
      .values()
      .next().value;

    if (!visualization) {
      return Promise.reject(
        new Error("Attempted to push task without a DnaVisualization")
      );
    }

    store.update((data) => {
      data.tasksMap[localId] = {
        localId,
        audioVisualizer,
        visualization,
        unwrappedPromise,
      };
      data.queue.push(localId);
      this._run();
      return data;
    });

    return unwrappedPromise.promise;
  }

  /**
   * @param {string} memoLocalId
   */
  cancel(memoLocalId) {
    log("cancel: memoLocalId: %O", memoLocalId);

    store.update((data) => {
      const currentTaskId = this._getCurrentTaskId(data);
      log("cancel: currentTaskId: %O", currentTaskId);
      if (memoLocalId === currentTaskId) {
        data.working = false;
      }

      let i;
      if ((i = data.queue.indexOf(memoLocalId)) !== -1) {
        data.queue.splice(i, 1);
      }

      if (Object.prototype.hasOwnProperty.call(data.tasksMap, memoLocalId)) {
        const { audioVisualizer, visualization } = data.tasksMap[memoLocalId];
        audioVisualizer.stop();
        visualization.forceStop();
        delete data.tasksMap[memoLocalId];
      }

      return data;
    });
  }

  /**
   * @private
   */
  _run() {
    const data = get(store);

    log(
      "_run: data.working: %O, data.queue.length: %O",
      data.working,
      data.queue.length
    );
    if (data.working || data.queue.length === 0) return;
    data.working = true;
    store.set(data);

    const taskId = data.queue[0];
    store.set(data);

    const task = data.tasksMap[taskId];

    log("_run: taskId: %O, task: %O", taskId, task);

    task.audioVisualizer.on("ended", async () => {
      log("_run: ended");

      if (this.getCurrentTaskId() !== taskId) {
        log("_run: canceled");
        return;
      }

      try {
        log("_run: render:started: task: %O", task);
        this.emit("render:started");

        // Render
        await task.visualization.render();
        if (this.getCurrentTaskId() !== taskId) {
          log("_run: render:canceled");
          return;
        }
        const blob = await task.visualization.asImageBlob();
        if (this.getCurrentTaskId() !== taskId) {
          log("_run: asImageBlob:canceled");
          return;
        }

        // Remove task after render completed
        data.queue.shift();
        delete data.tasksMap[taskId];
        store.set(data);

        // Notify about task completion
        log("_run: render:ended: task: %O", task);
        this.emit("render:ended");
        task.unwrappedPromise.resolve({ ...task, blob });
      } catch (e) {
        log("_run: render:failed: task: %O, error: %O", task, e);
        this.emit("render:failed", { error: e });
        console.error(e);
        task.unwrappedPromise.reject(e);
        return;
      }

      data.working = false;
      store.set(data);
      this._run();
    });

    if (!task.audioVisualizer.isEnded) {
      task.audioVisualizer.start();
    }
  }
}

MemoDnaRenderQueue.prototype.subscribe = store.subscribe.bind(store);

export const memoDnaRenderQueue = new MemoDnaRenderQueue();
