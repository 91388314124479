import { render, staticRenderFns } from "./MemoThumbnail.vue?vue&type=template&id=0bc2c878&scoped=true&"
import script from "./MemoThumbnail.vue?vue&type=script&lang=js&"
export * from "./MemoThumbnail.vue?vue&type=script&lang=js&"
import style0 from "./MemoThumbnail.vue?vue&type=style&index=0&id=0bc2c878&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc2c878",
  null
  
)

export default component.exports