// @ts-check

import { ffmpeg } from "./ffmpeg";

// ` DE aiff            Audio IFF`
// Extracts `aiff`
const formatExtractorRegex = new RegExp(".*E ([a-zA-Z0-9]+) +");

/**
 * @returns {Promise<object[]>}
 */
export function ffmpegGetSupportedFormats() {
  return ffmpeg((worker) =>
    worker.postMessage({ type: "run", arguments: ["-formats"] })
  )
    .then(extractFormatsFromStdout)
    .then((formats) => formats.map(formatToExtension))
    .then((formats) => formats.filter((f) => !!f))
    .then((formats) =>
      formats.map((f) => ({
        extension: f,
      }))
    );
}

function extractFormatsFromStdout(response) {
  const stdout = response.stdout || [];

  // Stdout starts with some info on what's comming next, it is ending with " --", eg.:
  // 0: "File formats:"
  // 1: " D. = Demuxing supported"
  // 2: " .E = Muxing supported"
  // 3: " --"
  let i = 0;
  while (stdout[i] != " --" && i < stdout.length) {
    ++i;
  }
  ++i;
  const formats = [];

  for (; i < stdout.length; ++i) {
    const regexResult = formatExtractorRegex.exec(stdout[i]);
    if (regexResult && regexResult.length > 1) {
      formats.push(regexResult[1]);
    }
  }

  return formats;
}

/**
 * Some of those require specific codec to make ffmpeg work, ffmpeg
 * attempts to deside on what codec to use by loking at the file extension
 *
 * @param {string} format
 */
function formatToExtension(format) {
  switch (format) {
    // These I guess could be fixed by searching for appropriate file extension ffmpeg to understand
    case "amr": // @see https://www.filesuffix.com/en/extension/amr
    case "daud": // @see https://ffmpeg.org/doxygen/0.11/daud_8c-source.html
    case "g722": // @see https://www.filesuffix.com/en/extension/g722
    case "g726": // @see https://www.filesuffix.com/en/extension/g726
    case "g726le":
    case "gsm": // @see https://www.filesuffix.com/en/extension/gsm
    case "oma": // @see https://www.filesuffix.com/en/extension/oma
    case "spx": // @see https://www.filesuffix.com/en/extension/spx
    case "truehd": // @see https://www.filesuffix.com/en/extension/truehd
      return null;

    case "dts": // dca codec is experimental
    case "matroska": // matroska is not really a file extension
    case "mlp": // mlp codec is experimental
    case "mmf": // Unsupported sample rate 48000, supported are 4000, 8000, 11025, 22050 and 44100
    case "null": // null is not really a file extension
    case "rso": // RSO only supports mono
      return null;

    default:
      return format;
  }
}
