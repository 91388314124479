// @ts-check

import rnnoise from "rnnoise-wasm";
import { MemoAudio } from "./MemoAudio";
import { toAudioBuffer } from "./soundUtils";

const SAMPLE_LENGTH = 480;

export class NoiseFilter {
  /** @type {Object} */
  _rnnoise;
  /** @type {boolean} */
  _isDestroyed;

  /**
   * @param {Object} rnnoiseInstance
   */
  constructor(rnnoiseInstance) {
    this._rnnoise = rnnoiseInstance;
    this._isDestroyed = false;
  }

  destroy() {
    if (!this._st || this._isDestroyed) return;
    this._pcmInputBuf && this._rnnoise._free(this._pcmInputBuf);
    this._pcmOutputBuf && this._rnnoise._free(this._pcmOutputBuf);
    this._rnnoise._rnnoise_destroy(this._st);
    this._st = 0;
    this._isDestroyed = true;
  }

  /**
   * @param {MemoAudio} memoAudio
   * @returns {Promise<AudioBuffer>}
   */
  filter(memoAudio) {
    if (this._isDestroyed) {
      throw new Error("This NoiseFilter instance is destroyed.");
    }

    try {
      this._heap = this._rnnoise.HEAPF32;
      this._createBuffers();
      this._st = this._rnnoise._rnnoise_create();
    } catch (e) {
      this.destroy();
      return Promise.reject(e);
    }

    return new Promise((resolve, reject) => {
      memoAudio
        .decode()
        .then((audioBuffer) => {
          const outputBuffer = this._processAudioBuffer(audioBuffer);
          this.destroy();
          resolve(outputBuffer);
        })
        .catch((e) => {
          this.destroy();
          reject("Error with decoding audio data" + e.err);
        });
    });
  }

  _createBuffers() {
    const bufferSize = SAMPLE_LENGTH * this._heap.BYTES_PER_ELEMENT;
    this._pcmInputBuf = this._rnnoise._malloc(bufferSize);
    if (!this._pcmInputBuf) {
      throw new Error("failed to create input buff");
    }
    this._pcmOutputBuf = this._rnnoise._malloc(bufferSize);
    if (!this._pcmOutputBuf) {
      this._rnnoise._free(this._pcmInputBuf);
      throw new Error("failed to create output buff");
    }

    this._pcmInputIndex = this._pcmInputBuf / this._heap.BYTES_PER_ELEMENT;
    this._pcmOutputIndex = this._pcmOutputBuf / this._heap.BYTES_PER_ELEMENT;
  }

  /**
   * @param {AudioBuffer} audioBuffer
   * @returns {AudioBuffer}
   */
  _processAudioBuffer(audioBuffer) {
    let denoisedChannels = [];
    for (let i = 0; i < audioBuffer.numberOfChannels; ++i) {
      denoisedChannels.push(this._processAudioBufferChannel(audioBuffer, i));
    }
    return toAudioBuffer(denoisedChannels);
  }

  /**
   * @param {AudioBuffer} audioBuffer
   * @param {number} channelNumber
   * @returns {Float32Array}
   */
  _processAudioBufferChannel(audioBuffer, channelNumber) {
    const arrayDataBuff = audioBuffer.getChannelData(channelNumber);
    const arrayDataBuffLen = arrayDataBuff.length;
    const outputBuffer = new Float32Array(arrayDataBuffLen);

    for (let i = 0; i < arrayDataBuffLen; i += SAMPLE_LENGTH) {
      let offset = SAMPLE_LENGTH;
      let end = i + offset;

      if (end > arrayDataBuffLen) {
        offset = arrayDataBuffLen - i;
        end = arrayDataBuffLen;
      }

      const sample = arrayDataBuff.slice(i, end);

      this._heap.set(sample, this._pcmInputIndex);

      this._rnnoise._rnnoise_process_frame(
        this._st,
        this._pcmOutputBuf,
        this._pcmInputBuf
      );

      const outSample = this._heap.slice(
        this._pcmOutputIndex,
        this._pcmOutputIndex + offset
      );

      outputBuffer.set(outSample, i);
    }

    return outputBuffer;
  }
}

/**
 * @returns {Promise<NoiseFilter>}
 */
NoiseFilter.create = () => {
  return rnnoise({
    locateFile: (path /*, scriptDirectory*/) => "/" + path,
  })
    .then((rnnoiseInstance) => new NoiseFilter(rnnoiseInstance))
    .catch((e) => console.error("Error in rnnoise initialization: ", e));
};
