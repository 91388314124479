// @ts-check

import EventEmitter from "eventemitter3";
import { getNewAudioContext } from "./soundUtils";

export class AudioBufferPlayer extends EventEmitter {
  /** @type {AudioBuffer} */
  _audioBuffer;
  /** @type {AudioContext} */
  _audioCtx;
  /** @type {AudioBufferSourceNode|undefined} */
  _source;
  /** @type {number} */
  _seekTo = 0.0;
  /**
   * Hot fix: Audio context holds total time it being ran
   * and even after recreating source timer does not reset
   * @type {number}
   */
  _timeOffset = 0.0;
  /**
   * Hot fix: Same issue as before, audio context counts
   * time since its creation
   * @type {boolean}
   */
  _started = false;

  /**
   * @param {AudioBuffer} audioBuffer
   */
  constructor(audioBuffer) {
    super();

    this._audioBuffer = audioBuffer;
    this._audioCtx = getNewAudioContext();
  }

  play() {
    if (!this._started) {
      this._timeOffset = this.currentTime;
      this._started = true;
    }

    if (!this._source) {
      this._source = this._createSource(0, this._seekTo);
    }

    if (this._audioCtx.state === "suspended") {
      this._audioCtx.resume();
    }
  }

  pause() {
    this._audioCtx.suspend();
  }

  get currentTime() {
    return this._audioCtx.currentTime - this._timeOffset;
  }

  /**
   * @param {number} currentTime
   */
  set currentTime(currentTime) {
    this._timeOffset += this.currentTime;

    if (this._source) {
      this._source.disconnect(this._audioCtx.destination);
      this._source = null;
    }

    if (this._audioCtx.state === "running") {
      this._source = this._createSource(0, currentTime);
    } else {
      this._seekTo = currentTime;
    }
  }

  _createSource(when, offset, duration) {
    // Get an AudioBufferSourceNode.
    // This is the AudioNode to use when we want to play an AudioBuffer
    const source = this._audioCtx.createBufferSource();

    // set the buffer in the AudioBufferSourceNode
    source.buffer = this._audioBuffer;

    // connect the AudioBufferSourceNode to the
    // destination so we can hear the sound
    source.connect(this._audioCtx.destination);

    source.addEventListener("ended", () => {
      this.emit("ended");
    });

    source.start(when, offset, duration);

    return source;
  }
}
