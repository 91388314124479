// @ts-check

import { toHHMMSS } from "../utils";
import { ffmpeg } from "./ffmpeg";
import { FfmpegFile } from "./FfmpegFile";

/**
 * @typedef {Object} Slice
 * @property {number} startTime Seconds
 * @property {number} endTime Seconds
 */

/**
 * Slices the in audio into multiple files
 *
 * @param {FfmpegFile|File} file
 * @param {Slice[]} slices
 * @returns {Promise<import("./ffmpeg").FfmpegResponseDone<ArrayLike>>}
 */
export function ffmpegSlice(file, slices = []) {
  if (slices.length === 0) {
    return Promise.reject("One or more slices are required");
  }

  return ffmpeg(runCb.bind(undefined, file, slices));
}

/**
 * @param {Slice[]} slices
 * @returns {string[]}
 */
function slicesToFfmpegArguments(slices, outFormat) {
  let args = [];

  let i = 0;
  for (const slice of slices) {
    // -ss 00:00:00.00 -to 00:30:00.00 -vn -acodec copy -f f32le -sn output0.pcm
    args = args.concat([
      "-ss",
      toHHMMSS(slice.startTime),
      "-to",
      toHHMMSS(slice.endTime),
      "-vn",
      "-acodec",
      "copy",
      // "-f",
      // "f32le",
      "output" + i + "." + (outFormat ? outFormat : "pcm"),
    ]);
    ++i;
  }

  return args;
}

/**
 * @param {FfmpegFile|File} file
 * @param {Slice[]} slices
 * @param {Worker} worker
 */
function runCb(file, slices, worker) {
  let ffmpegFile;

  if (file instanceof FfmpegFile) {
    ffmpegFile = Promise.resolve(file);
  } else if (file instanceof File) {
    ffmpegFile = FfmpegFile.createFromFile(file);
  } else {
    const buff = file.getChannelData(0).slice(0).buffer;

    const args = [
      "-y",
      "-f",
      "f32le",
      "-i",
      "input.pcm",
      ...slicesToFfmpegArguments(slices),
    ];

    console.log("ffmpegSlice", args.join(" "));

    worker.postMessage(
      {
        type: "run",
        MEMFS: [{ name: "input.pcm", data: buff }],
        arguments: args,
      },
      [buff]
    );
    return;
  }

  ffmpegFile.then((file) => {
    console.log(file);

    const args = [
      "-y",
      ...file.getArguments(),
      ...slicesToFfmpegArguments(slices, file.extension),
    ];

    console.log("ffmpegSlice::runCb:args:", args.join(" "));

    const message = {
      type: "run",
      MEMFS: [file],
      arguments: args,
    };

    console.log("ffmpegSlice::runCb:message:", message);

    worker.postMessage(message, [file.data]);
  });
}
