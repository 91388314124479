import Vue from "vue";

const f = (v) => (v < 10 ? "0" + v : v);

Vue.filter("formatDate", (value) => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day}.${f(month)}.${year}`;
});

Vue.filter("formatTimeUntilSeconds", (value) => {
  value = Math.round(value);
  const minutes = Math.floor(value / 60);
  return f(minutes) + ":" + f(value % 60);
});

Vue.filter("formatTimeUntilMilliseconds", (value) => {
  const thousandth = value / 1000;
  const seconds = Math.floor(thousandth) % 60;
  const minutes = Math.floor(thousandth / 60);
  return (
    f(minutes) + ":" + f(seconds) + "." + f(Math.floor((value % 1000) / 10))
  );
});

Vue.filter("preciseFormatTime", (value) => {
  const minutes = Math.floor(value / 60);
  let rest = value - minutes * 60;
  const seconds = Math.floor(rest);
  const miliseconds = rest - seconds;
  return f(minutes) + ":" + f(seconds) + "." + Math.round(miliseconds * 100);
});
