import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/sass/main.sass";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "@/lib/filters/timeFormatter";
import VueSweetalert2 from "vue-sweetalert2";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSweetalert2, {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", VueSweetalert2.stopTimer);
    toast.addEventListener("mouseleave", VueSweetalert2.resumeTimer);
  },
});
import "sweetalert2/dist/sweetalert2.min.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
