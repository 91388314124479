// @ts-check

import { extractExtensionFromFileName } from "../utils";
import { ffmpeg } from "./ffmpeg";
import { FfmpegFile } from "./FfmpegFile";
import { ffmpegSlice } from "./ffmpegSlice";

/**
 * Slices the in audio into multiple files and then combine them into one
 *
 * @param {FfmpegFile|File} file
 * @param {import("./ffmpegSlice").Slice[]} slices
 * @returns {Promise<import("./ffmpeg").FfmpegResponseDone<ArrayLike>>}
 */
export function ffmpegExtractSlice(file, slices = []) {
  let ffmpegFile;
  if (file instanceof FfmpegFile) {
    ffmpegFile = Promise.resolve(file);
  } else if (file instanceof File) {
    ffmpegFile = FfmpegFile.createFromFile(file);
  } else {
    return Promise.reject(`Type of file === ${typeof file} is unsupported`);
  }

  return ffmpegFile.then((file) =>
    ffmpegSlice(file, slices).then((data) => {
      if (slices.length === 1) {
        return data;
      } else {
        return ffmpeg(runCb.bind(undefined, file, data.MEMFS));
      }
    })
  );
}

/**
 * @param {import("./ffmpeg").MemFsEntry<ArrayLike>[]} files
 * @returns {string[]}
 */
function generateInputArguments(files) {
  let args = [];

  // let i = 0;
  // const filesLength = files.length;
  // for (; i < filesLength; ++i) {
  //   args = args.concat([
  //     "-f",
  //     "f32le",
  //     "-i",
  //     "input" + i + "." + (outFormat ? outFormat : "pcm"),
  //   ]);
  // }
  files.forEach((file) => (args = args.concat(["-i", file.name])));

  return args;
}

/**
 * @param {import("./ffmpeg").MemFsEntry<ArrayLike>[]} files
 * @param {Worker} worker
 */
function runCb(ffmpegFile, files, worker) {
  files = files.map((file, i) => ({
    name: "input" + i + "." + extractExtensionFromFileName(file.name),
    data: file.data,
  }));
  const filesLength = files.length;
  console.log(files);

  console.log(ffmpegFile);
  // -y -f f32le -i input0.pcm -f f32le -i input1.pcm -filter_complex concat=n=2:v=0:a=1 -f f32le -vn result.pcm
  const args = [
    "-y",

    ...generateInputArguments(files),

    "-filter_complex",
    "concat=n=" + filesLength + ":v=0:a=1",
    "result." + ffmpegFile.extension,
  ];
  console.log("ffmpegExtractSlice", args.join(" "));

  worker.postMessage(
    {
      type: "run",
      MEMFS: files,
      arguments: args,
    },
    files.map((file) => file.data.buffer.slice(0))
  );
}
